import React from 'react';
import T from 'prop-types';
import moment from 'moment';
import ResultsListItem from '@icare/components/lib/ResultsList/ResultsListItem';
import Tag from '@icare/components/lib/Tag';
import RouteUtils from '~/core/utils/RouteUtils';
import {crifPaths} from '~/paths';

import NotificationStatus from '../../constants/NotificationStatus';
import OperationType from '../../constants/OperationType';

const Notification = ({notification, content, resultItemKey, bellIconNotificationOverlay}) => {
	const getTagStatus = () => {
		if (notification.status === NotificationStatus.COMPLETED) {
			return {color: Tag.color.TEAL, text: content.completed};
		} else if (notification.status === NotificationStatus.IN_PROGRESS) {
			return {color: Tag.color.PURPLE, text: content.workInProgress};
		} else {
			return {color: Tag.color.RED, text: content.somethingWentWrong};
		}
	};

	const getTitle = () => {
		const projectName = notification.projectName ? `- ${notification.projectName}` : '';
		switch (notification.operation) {
			case OperationType.CREATE_QUOTE:
				return `${content.createQuote} ${projectName}`;
			case OperationType.UPDATE_QUOTE:
				return `${content.updateQuote} ${projectName}`;
			case OperationType.REJECT_QUOTE:
				return `${content.declineQuote} ${projectName}`;
			case OperationType.CREATE_POLICY:
				return `${content.createPolicy} ${projectName}`;
			case OperationType.UPDATE_POLICY:
				return `${content.updatePolicy} ${projectName}`;
			case OperationType.CANCEL_POLICY:
				return `${content.cancelPolicy} ${projectName}`;
			default:
				return projectName;
		}
	};

	const getURL = () => {
		if (notification.status === NotificationStatus.COMPLETED && notification.showURL) {
			switch (notification.operation) {
				case OperationType.CREATE_QUOTE:
				case OperationType.UPDATE_QUOTE:
					return RouteUtils.setQuoteLink(crifPaths.details, notification.quoteNumber, notification.agencyId);
				case OperationType.REJECT_QUOTE:
					return RouteUtils.setQuoteLink(
						crifPaths.quotePolicyCancelConfirmationPage,
						notification.quoteNumber,
						notification.agencyId,
					);
				case OperationType.CREATE_POLICY:
					return RouteUtils.setPolicyLink(crifPaths.details, notification.policyNumber, notification.agencyId);
				case OperationType.UPDATE_POLICY:
					return RouteUtils.setQuoteLink(crifPaths.details, notification.quoteNumber, notification.agencyId);
				case OperationType.CANCEL_POLICY:
					return RouteUtils.setPolicyLink(
						crifPaths.quotePolicyCancelConfirmationPage,
						notification.policyNumber,
						notification.agencyId,
					);
				default:
					return null;
			}
		}
		return null;
	};

	let arr = [];
	if (!bellIconNotificationOverlay) {
		arr = [{value: `Transaction id ${notification.policyNumber || notification.quoteNumber || ''} `}];
	}

	const detailedItems = [
		{
			value: (
				<Tag color={getTagStatus().color} text={getTagStatus().text}>
					{getTagStatus().text}
				</Tag>
			),
		},
		{value: moment(notification.createdOn).fromNow()},
		...arr,
	];
	return <ResultsListItem detailItems={detailedItems} title={getTitle()} key={resultItemKey} path={getURL()} />;
};

Notification.propTypes = {
	resultItemKey: T.string.isRequired,
	content: T.object.isRequired,
	notification: T.shape({
		status: T.string,
		operation: T.string,
		createdOn: T.string,
		id: T.string,
		projectName: T.string,
		quoteNumber: T.string,
		agencyId: T.string,
		type: T.string,
		policyNumber: T.string,
		showURL: T.bool,
	}).isRequired,
	bellIconNotificationOverlay: T.bool,
};

Notification.defaultProps = {
	bellIconNotificationOverlay: false,
};

export default Notification;
