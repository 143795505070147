import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.userTokenVerificationPage,
		title: 'User Token Verification',
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/UserTokenVerificationPage'); // eslint-disable-line
		}),
	},
];

export default routes;
