import './asset-path';

/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
/* eslint-enable */

import App from './App';

ReactDOM.render(<App />, window.document.getElementById('app'));
