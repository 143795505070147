export const OperationType = Object.freeze({
	CREATE_QUOTE: 'CreateQuote',
	UPDATE_QUOTE: 'UpdateQuote',
	CREATE_POLICY: 'CreatePolicy',
	UPDATE_POLICY: 'UpdatePolicy',
	REJECT_QUOTE: 'RejectQuote',
	CANCEL_POLICY: 'CancelPolicy',
});

export default OperationType;
