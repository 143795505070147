import React from 'react';
import T from 'prop-types';
import {Link} from 'react-router-dom';
import Layout from '@icare/layouts/lib/Layout';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import ContentHeader from '@icare/components/lib/ContentHeader';
import ButtonGroup from '@icare/components/lib/ButtonGroup';
import Button from '@icare/components/lib/Button';
import {sitePaths} from '~/paths';

@injectContent(['errorContent'])
class FatalErrorPage extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
	};

	render() {
		const {errorContent} = this.props.content;
		return (
			<Layout type={Layout.Type.ONE_COLUMN}>
				<ContentHeader heading={errorContent.technicalErrorTitle} subHeading={errorContent.technicalErrorSubtitle} />
				<p>{errorContent.technicalErrorText}</p>
				<ButtonGroup>
					<Button tag={Link} to={sitePaths.cfeGeneralEnquiriesFormPage} variant={Button.Variant.SECONDARY}>
						{errorContent.contactButton}
					</Button>
					<Button tag={Link} to={sitePaths.dashboard} variant={Button.Variant.PRIMARY}>
						{errorContent.homeButton}
					</Button>
				</ButtonGroup>
			</Layout>
		);
	}
}

export default FatalErrorPage;
