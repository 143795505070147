/* eslint-disable import/prefer-default-export */
import Auth from '@icare/auth-okta/lib/Auth';

const SESSION = {INACTIVE: 'INACTIVE'};

export const getUser = async () => {
	const auth = await Auth.sharedInstance.getInstance();
	const session = await auth._okta.session.get();
	// okta session not logged in
	if (session.status === SESSION.INACTIVE) {
		throw new Error('Identity session INACTIVE - unable to get user session');
	} else {
		return session.user();
	}
};

export const getRecoveryQuestion = async () => {
	const user = await getUser();
	return (
		'credentials' in user && 'recovery_question' in user.credentials && user.credentials.recovery_question.question
	);
};
