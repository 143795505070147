import {types, flow} from 'mobx-state-tree';
import {formatWithMonth} from '@icare/core/lib/utils/FormattingUtils';
import {rms, sw} from '~/core/constants/Agencies';
import {
	ReferralConstants,
	QuoteConstants,
	DistributionConstants,
	PolicyConstants,
} from '~/core/constants/ApplicationConstants';
import AuthenticatedServices from '~/core/services/AuthenticatedServices';
import PolicyServices from '~/core/services/PolicyServices';
import RouteUtils from '~/core/utils/RouteUtils';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';
import {crifPaths} from '~/paths';
import {ASYNC_MODAL_FEATURE} from '~/config';
import NotificationStatus from '~/pods/site/constants/NotificationStatus';

import PolicyApplicationStoreDefault from './PolicyApplicationStoreDefault';
import PolicyApplicationStoreModel from './PolicyApplicationStoreModels';

import PolicyRequestBuilder from '../utils/PolicyRequestBuilder';

export const defaultState = PolicyApplicationStoreDefault;

export const PolicyApplicationStore = types
	.model('PolicyApplicationStore', PolicyApplicationStoreModel)
	.views(self => {
		return {
			hasEditAccess(id = null) {
				const {agency, policyData} = self;
				if (id) {
					return id === agency.id;
				}
				if (policyData) {
					const matchedPolicy = policyData.data.attributes.agencyId === agency.id;
					const notAnnual = policyData.data.attributes.distributionType !== DistributionConstants.Annual;
					return matchedPolicy && notAnnual;
				}

				return false;
			},
			hasAdditionalInfo() {
				if (!self.policyData) {
					return false;
				}
				return !!Object.keys(self.policyData.data.attributes.additionalInfo).length;
			},
			isPolicy() {
				if (self.policyData) {
					return self.policyData.data.type === PolicyConstants.Type;
				}
				return self.urlParams.typeId === RouteUtils.PolicyLabel;
			},
			isQuote() {
				if (self.policyData) {
					return self.policyData.data.type === QuoteConstants.Type;
				}
				return self.urlParams.typeId === RouteUtils.QuoteLabel;
			},
			isReferral() {
				if (!self.policyData) {
					return false;
				}
				return self.policyData.data.attributes.status === ReferralConstants.Status;
			},
			isEndorsed() {
				if (!self.policyData) {
					return false;
				}
				const {type, attributes} = self.policyData.data;

				switch (type) {
					case ReferralConstants.Type:
					case QuoteConstants.Type:
						return !!attributes.policyId;
					case PolicyConstants.Type:
						//A policy on the application page is always an endorsement
						return true;
					default:
						return false;
				}
			},
			isRms(id = null) {
				const {policyData, agency} = self;
				if (id) {
					return id === rms.id;
				}

				if (policyData) {
					return policyData.data.attributes.agencyId === rms.id;
				}
				return agency ? agency.id === rms.id : false;
			},
			isSw(id = null) {
				if (id) {
					return id === sw.id;
				}
				return self.policyData ? self.policyData.data.attributes.agencyId === sw.id : false;
			},
			getDetails() {
				if (self.policyData) {
					const {data} = self.policyData;
					return {
						id: data.attributes.quoteId || data.id,
						status: data.attributes.status,
						type: data.type,
					};
				}
				return {
					id: null,
					status: QuoteConstants.Status,
					type: QuoteConstants.Type,
				};
			},
			getDetailsLink() {
				if (!self.policyData) {
					return crifPaths.policyListing;
				}
				const {data} = self.policyData;
				return self.isPolicy()
					? RouteUtils.setPolicyLink(crifPaths.details, data.id, data.attributes.agencyId)
					: RouteUtils.setQuoteLink(crifPaths.details, data.id, data.attributes.agencyId);
			},
			showQuoteWell() {
				return self.status.hasSubmitted && self.policyData && !self.isReferral() && !self.status.error;
			},
			showReferralWell() {
				return self.status.hasSubmitted && self.policyData && self.isReferral() && !self.status.error;
			},
			showErrorWell() {
				return self.policyData && self.status.patchError;
			},
		};
	})
	.actions(self => {
		const updateUrlParams = params => {
			self.urlParams = params;
		};
		const updatePolicyData = policyData => {
			self.policyData = policyData;
		};
		const updateSubmitted = value => {
			self.status.hasSubmitted = value;
		};
		const updateOpenAsyncModal = value => {
			self.status.openAsyncModal = value;
		};
		const setReferral = value => {
			self.referrals.isReferral = value;
		};
		const setNotifications = response => {
			const notification = {
				...response.data.attributes,
				id: response.data.id,
				type: response.data.type,
			};
			self.asyncNotification.notifications.push(notification);
		};
		const checkNewReferencePeriods = () => {
			if (self.policyData) {
				const {testingPeriod, postCompletionPeriod} = self.policyData.data.attributes.riskDates;
				const newTP = self.referenceData.testingPeriod;
				const newCP = self.referenceData.completionPeriod;

				if (!newCP.filter(period => period.id === postCompletionPeriod).length) {
					newCP.push({
						value: postCompletionPeriod,
						label: formatWithMonth(postCompletionPeriod),
						id: postCompletionPeriod,
					});
				}
				if (!newTP.filter(period => period.id === testingPeriod).length) {
					newTP.push({
						value: testingPeriod,
						label: formatWithMonth(testingPeriod),
						id: testingPeriod,
					});
				}
				self.referenceData = {
					...self.referenceData,
					completionPeriod: newCP,
					testingPeriod: newTP,
				};
			}
		};

		const updateProjectValues = data => {
			const {contractWorks, liabilities, professionalIndemnity} = data;

			if (contractWorks) {
				self.projectValues.contractWorks.policyExcess = {
					...self.projectValues.contractWorks.policyExcess,
					...contractWorks.policyExcess,
				};
				self.projectValues.contractWorks.leg3Excess = {
					...self.projectValues.contractWorks.leg3Excess,
					...contractWorks.leg3Excess,
				};
				self.projectValues.contractWorks.existingProperty = {
					...self.projectValues.contractWorks.existingProperty,
					...contractWorks.existingProperty,
				};
			}

			if (liabilities) {
				self.projectValues.liabilities.publicAndProductLiabilityLimit = {
					...self.projectValues.liabilities.publicAndProductLiabilityLimit,
					...liabilities.publicAndProductLiabilityLimit,
				};
				self.projectValues.liabilities.environmentImpairmentLiabilityLimit = {
					...self.projectValues.liabilities.environmentImpairmentLiabilityLimit,
					...liabilities.environmentImpairmentLiabilityLimit,
				};
				self.projectValues.liabilities.publicLiabilityExcess = {
					...self.projectValues.liabilities.publicLiabilityExcess,
					...liabilities.publicLiabilityExcess,
				};
				self.projectValues.liabilities.workerExcess = {
					...self.projectValues.liabilities.workerExcess,
					...liabilities.workerExcess,
				};
			}

			if (professionalIndemnity) {
				self.projectValues.professionalIndemnity.liabilityLimit = {
					...self.projectValues.professionalIndemnity.liabilityLimit,
					...professionalIndemnity.liabilityLimit,
				};
				self.projectValues.professionalIndemnity.indemnityExcess = {
					...self.projectValues.professionalIndemnity.indemnityExcess,
					...professionalIndemnity.indemnityExcess,
				};
			}
		};
		const updateReferenceData = data => {
			const {projectType, testingPeriod, completionPeriod} = data;

			const mappedProjectType = projectType.map(type => {
				return {
					value: type.name,
					label: type.name,
					id: type.name,
				};
			});
			mappedProjectType.push({
				id: '__placeholder',
				label: 'Please Select',
				value: '',
				disabled: true,
			});

			const mappedCompletionPeriod = completionPeriod.map(period => {
				return {
					value: period,
					label: formatWithMonth(period),
					id: period,
				};
			});

			const mappedTestingPeriod = testingPeriod.map(period => {
				return {
					value: period,
					label: formatWithMonth(period),
					id: period,
				};
			});

			self.referenceData = {
				projectType: mappedProjectType,
				completionPeriod: mappedCompletionPeriod,
				testingPeriod: mappedTestingPeriod,
				defaultTestingPeriod: data.defaultTestingPeriod,
				defaultPostCompletionPeriod: data.defaultPostCompletionPeriod,
			};
		};

		const updateReferralTypes = data => {
			self.referralTypes = [];
			data.projectType.map(type => {
				if (type.classification === ReferralConstants.Classification) {
					self.referralTypes.push(type.name);
				}
			});
		};

		const getAgencies = flow(function* () {
			self.status.isBusy = true;

			try {
				const response = yield AuthenticatedServices.getAgencies();
				self.agency = response.attributes.items.find(agency => agency.id === response.id);
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				self.status.agencyError = true;
				throw error;
			}
		});

		const getQuote = flow(function* () {
			const {policyId, agencyId} = self.urlParams;
			self.status.isBusy = true;
			self.status.error = null;
			try {
				const response = self.isPolicy()
					? yield PolicyServices.getPolicy(policyId, agencyId)
					: yield PolicyServices.getQuote(policyId, agencyId);

				updatePolicyData(response);
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				throw error;
			}
		});

		const setSessionItems = response => {
			const sessionData = JSON.parse(window.sessionStorage.getItem('notifications')) || [];
			const notification = {
				...response.data.attributes,
				id: response.data.id,
				type: response.data.type,
			};
			sessionData.push(notification);
			window.sessionStorage.setItem('notifications', JSON.stringify(sessionData));
		};

		const create = flow(function* (step1Data) {
			self.status.isBusy = true;
			self.status.error = null;
			try {
				const request = PolicyRequestBuilder.buildStep1Request(step1Data, self.getDetails());
				const response = yield PolicyServices.addQuote(request);
				if (ASYNC_MODAL_FEATURE) {
					setNotifications(response);
					setSessionItems(response);
				} else {
					updatePolicyData(response);
					updateSubmitted(true);
					self.referrals.isReferral = response.data.type === ReferralConstants.Type;
				}
				self.status.isBusy = false;
				self.status.postError = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				self.status.postError = true;
			}
		});

		const patch = flow(function* (step1Data = null, step2Data = null) {
			self.status.isBusy = true;
			self.status.error = null;
			try {
				const request = step1Data
					? PolicyRequestBuilder.buildStep1Request(
						step1Data,
						self.getDetails(),
						self.policyData.data.attributes.additionalInfo,
					)
					: PolicyRequestBuilder.buildStep2Request(step2Data, self.policyData.data);
				const response = self.isPolicy()
					? yield PolicyServices.patchPolicy(request)
					: yield PolicyServices.patchQuote(request);
				if (ASYNC_MODAL_FEATURE) {
					setNotifications(response);
					setSessionItems(response);
				} else {
					updatePolicyData(response);
					updateSubmitted(true);
					self.referrals.isReferral = response.data.type === ReferralConstants.Type;
				}
				self.status.isBusy = false;
				self.status.patchError = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				self.status.patchError = true;
			}
		});

		const bindPolicyDetails = flow(function* (step2Data) {
			self.status.isBusy = true;
			self.status.error = null;

			try {
				const request = PolicyRequestBuilder.buildStep2Request(step2Data, self.policyData.data);
				//Post Policy requires attributes.quoteId instead of Id
				request.attributes.quoteId = request.id;
				delete request.id;
				const response = yield PolicyServices.bindPolicy(request);
				if (ASYNC_MODAL_FEATURE) {
					setNotifications(response);
					setSessionItems(response);
				}
				AnalyticsHandler.submitQuote(response);
				self.isBindingPolicy = false;
				self.status.isBusy = false;
				self.status.postError = false;
				return response;
			} catch (error) {
				self.isBindingPolicy = false;
				self.status.isBusy = false;
				self.status.postError = true;
				self.status.error = error;
				throw error;
			}
		});

		const getProjectValues = flow(function* (
			agencyId = self.policyData.data.attributes.agencyId,
			sumsInsured = parseInt(self.policyData.data.attributes.products.contractWorks.terms.sumsInsured),
		) {
			self.isGettingValues = true;
			try {
				if (sumsInsured || sumsInsured === 0) {
					const response = yield PolicyServices.getProjectValues(agencyId, sumsInsured);
					updateProjectValues(response);
					self.isGettingValues = false;
					self.status.projectValuesError = false;
					self.projectValues.lastSumsInsured = sumsInsured;
					return true;
				} else {
					self.projectValues = PolicyApplicationStoreDefault.projectValues;
					self.isGettingValues = false;
					self.status.projectValuesError = true;
					return false;
				}
			} catch (error) {
				self.isGettingValues = false;
				self.status.projectValuesError = true;
				self.status.error = error;
				throw error;
			}
		});

		const getReferenceData = flow(function* () {
			self.status.isBusy = true;
			try {
				const response = yield PolicyServices.getReferenceData(self.agency.id);
				updateReferenceData(response);
				updateReferralTypes(response);
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				throw error;
			}
		});

		const getStatus = flow(function* (ackId, showAllNotifications = false) {
			try {
				const response = yield PolicyServices.getNotification(ackId);
				self.status.isBusy = false;
				if (showAllNotifications) {
					setNotifications(response);
					window.sessionStorage.setItem('notifications', JSON.stringify(self.asyncNotification.notifications));
				} else {
					return response;
				}
			} catch (err) {
				// if error, change notification status to error
				self.status.isBusy = false;
				const notifications = JSON.parse(window.sessionStorage.getItem('notifications'));
				const notification = notifications.find(notification => notification.ackId === ackId);
				if (notification) {
					notification.status = NotificationStatus.ERROR;
					self.asyncNotification.notifications.push(notification);
					window.sessionStorage.setItem('notifications', JSON.stringify(self.asyncNotification.notifications));
				} else {
					self.status.error = err;
					throw err;
				}
			}
		});

		const getNotificationStatus = () => {
			const notifications = JSON.parse(window.sessionStorage.getItem('notifications')) || [];
			self.asyncNotification.notifications = [];
			if (notifications.length > 0) {
				notifications.forEach(item => {
					if (item.status === NotificationStatus.COMPLETED || item.status === NotificationStatus.ERROR) {
						self.asyncNotification.notifications.push(item);
					} else {
						getStatus(item.ackId, true);
					}
				});
			}
		};

		const reset = () => {
			self.policyData = defaultState.policyData;
			self.agency = defaultState.agency;
			self.status = defaultState.status;
			self.isGettingValues = defaultState.isGettingValues;
			self.referrals = defaultState.referrals;
			self.policyData = defaultState.policyData;
			self.projectValues = defaultState.projectValues;
		};

		return {
			updateContaminatedReferral(value) {
				self.referrals.contaminatedLand = value === 'true';
			},
			updateSumsReferral(value) {
				self.referrals.sumsInsured = value > 100000000;
			},
			updateTypeReferral(value) {
				self.referrals.projectType = self.referralTypes.includes(value);
			},
			updateProjectValuesError(value) {
				self.status.projectValuesError = value;
			},
			updateError(error) {
				self.status.error = error;
			},
			getAgencies,
			create,
			getQuote,
			getProjectValues,
			getReferenceData,
			updateReferenceData,
			checkNewReferencePeriods,
			updateReferralTypes,
			updatePolicyData,
			updateProjectValues,
			updateSubmitted,
			updateOpenAsyncModal,
			updateUrlParams,
			bindPolicyDetails,
			patch,
			reset,
			setSessionItems,
			setNotifications,
			getNotificationStatus,
			getStatus,
			setReferral,
		};
	});

export default PolicyApplicationStore;
