const bellIconHover = notifications => {
	// on mouse enter of of li element, make .notificationOverlay class have display block

	if (!document.querySelector('#main-header')) {
		return;
	}
	const bellIcon = document.querySelector('li:nth-of-type(4)');

	const notificationOverlay = document.querySelector('#bellIconNotificationOverlay');

	bellIcon.addEventListener('mouseover', () => {
		if (notifications.length <= 0) {
			return;
		}
		const left = bellIcon.getBoundingClientRect().x;
		notificationOverlay.style.left = window.innerWidth < 1800 ? `calc(${left}px - 125px)` : `${left}px`;
		notificationOverlay.style.display = 'block';
	});
	notificationOverlay.addEventListener('mouseleave', () => (notificationOverlay.style.display = 'none'));
};

export default bellIconHover;
