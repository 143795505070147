import {formatToISO} from '@icare/core/lib/utils/FormattingUtils';
import {rms} from '~/core/constants/Agencies';

export default class PolicyRequestBuilder {

	static buildStep1Request(step1Data, details, additionalInfo = null) {
		const {projectDetails, projectValues, projectDates} = step1Data;
		const {status, type, id} = details;
		const isRms = projectDetails.agencyName === rms.id;

		return {
			type: type,
			id: id || null,
			attributes: {
				additionalInfo,
				agencyId: projectDetails.agencyName,
				lossesReported: projectDetails.lossesReported ? projectDetails.lossesReported === 'true' : null,
				governmentContractNumber: projectDetails.governmentContractNumber || null,
				projectName: projectDetails.projectName || null,
				projectType: projectDetails.projectType,
				contaminatedLandRegistered: projectDetails.contaminatedLand === 'true',
				status: status,
				inceptionDate: formatToISO(projectDates.inceptionDate),
				riskDates: {
					coverInceptionDate: formatToISO(projectDates.inceptionDate),
					constructionStartDate: formatToISO(projectDates.constructionStartDate),
					constructionEndDate: formatToISO(projectDates.constructionEndDate),
					testingPeriod: projectDates.testingPeriod,
					postCompletionPeriod: projectDates.completionDate,
				},
				projectValues: {
					contractWorks: {
						sumsInsured: projectValues.sumsInsured.replace(/\D+/g, ''),
						existingProperty: projectValues.existingProperty,
						policyExcess: projectValues.contractWorksExcess,
						leg3Excess: isRms ? null : projectValues.leg3Excess,
					},
					liabilities: {
						publicAndProductLiabilityLimit: projectValues.publicProductLiability,
						environmentImpairmentLiabilityLimit: projectValues.environmentImpairmentLiability,
						publicLiabilityExcess: projectValues.publicLiabilityExcess,
						workerExcess: projectValues.workerWorkerExcess,
					},
					professionalIndemnity: {
						liabilityLimit: isRms ? projectValues.limitOfLiability : null,
						indemnityExcess: isRms ? projectValues.professionalExcess : null,
					},
					additionalProducts: projectValues.additionalProducts === 'true',
				},
			},
		};
	}

	static buildStep2Request(step2Data, policyData) {
		const {purchaseDetails, contractorsDetails, mainProjectLocation, contactDetails} = step2Data;
		const {attributes} = policyData;
		const {contractWorks, publicImpairmentLiability, professionalIndemnity, additionalProducts} = attributes.products;

		if (mainProjectLocation.locationOfProject === 'true') {
			mainProjectLocation.longitude = null;
			mainProjectLocation.latitude = null;
		}

		const additionalInfo = {
			wbsNumber: purchaseDetails.wbsNumber || null,
			purchaseOrder: purchaseDetails.purchaseOrder || null,
			contractors: [
				{
					legalName: contractorsDetails.legalname1 || null,
					abn: contractorsDetails.abn1 || null,
				},
				{
					legalName: contractorsDetails.legalname2 || null,
					abn: contractorsDetails.abn2 || null,
				},
				{
					legalName: contractorsDetails.legalname3 || null,
					abn: contractorsDetails.abn3 || null,
				},
			],
			projectLocation: {
				streetAddress: {
					addressLine1: mainProjectLocation.addressLineOne || null,
					addressLine2: mainProjectLocation.addressLineTwo || null,
					city: mainProjectLocation.suburb || null,
					state: mainProjectLocation.suburb ? (mainProjectLocation.state || 'NSW') : null,
					postCode: mainProjectLocation.postcode || null,
				},
				geoLocation: {
					lat: mainProjectLocation.latitude || null,
					long: mainProjectLocation.longitude || null,
				},
			},
			contactDetails: {
				accountsPayableEmail: contactDetails.accountsPayableEmailAddress,
				projectContact: {
					firstName: contactDetails.firstName || null,
					lastName: contactDetails.lastName || null,
					email: contactDetails.emailAddress || null,
					phone: contactDetails.contactNumber || null,
				},
			},
		};
		return {
			...policyData,
			attributes: {
				...attributes,
				additionalInfo,
				governmentContractNumber: purchaseDetails.governmentContractNumber || attributes.governmentContractNumber,
				projectValues: {
					additionalProducts: additionalProducts,
					contractWorks: {
						sumsInsured: contractWorks.terms.sumsInsured,
						existingProperty: contractWorks.terms.existingProperty,
						policyExcess: contractWorks.terms.policyExcess,
						leg3Excess: contractWorks.terms.leg3Excess,
					},
					liabilities: {
						publicAndProductLiabilityLimit: publicImpairmentLiability.terms.liabilityLimit,
						environmentImpairmentLiabilityLimit: publicImpairmentLiability.terms.environmentImpairmentLiabilityLimit,
						publicLiabilityExcess: publicImpairmentLiability.terms.publicLiabilityExcess,
						workerExcess: publicImpairmentLiability.terms.workerExcess,
					},
					professionalIndemnity: {
						liabilityLimit: professionalIndemnity ? professionalIndemnity.terms.liabilityLimit : null,
						indemnityExcess: professionalIndemnity ? professionalIndemnity.terms.indemnityExcess : null,
					},
				},
			},
		};
	}
}
