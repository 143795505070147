export default class CfeFormRequestBuilder {
	static createRequestFromStore(storeData) {
		const {formData, requestType} = storeData;

		let contactMeBackBool;

		if (formData.cfeGlobal.contactMeBack === 'yes') {
			contactMeBackBool = true;
		} else if (formData.cfeGlobal.contactMeBack === 'no') {
			contactMeBackBool = false;
		}

		const payload = {
			type: requestType,
			attributes: {
				contact: {
					responseRequired: contactMeBackBool,
					firstName: formData.cfeGlobal.firstName,
					lastName: formData.cfeGlobal.lastName,
					method: formData.cfeGlobal.contactMethod,
					phoneNumber: formData.cfeGlobal.contactPhone,
					phoneType: formData.cfeGlobal.phoneType,
					email: formData.cfeGlobal.contactEmail,
				},
			},
		};

		let otherDescriptionValue = '';

		if (requestType === 'Enquiry') {
			if (formData.cfeGeneral.enquiryType === 'IfNSW Other') {
				otherDescriptionValue = formData.cfeGeneral.enquiryTypeOther;
			};

			payload.attributes.type = formData.cfeGeneral.enquiryType;
			payload.attributes.details = formData.cfeGeneral.enquiryDetails;
			payload.attributes.desiredOutcome = '';
			payload.attributes.otherDescription = otherDescriptionValue;
		}

		if (requestType === 'Complaint') {
			if (formData.cfeComplaint.complaintType === 'IfNSW Other') {
				otherDescriptionValue = formData.cfeComplaint.complaintTypeOther;
			};

			payload.attributes.type = formData.cfeComplaint.complaintType;
			payload.attributes.details = formData.cfeComplaint.complaintDetails;
			payload.attributes.desiredOutcome = formData.cfeComplaint.desiredOutcome;
			payload.attributes.otherDescription = otherDescriptionValue;
		}

		if (requestType === 'Feedback') {
			if (formData.cfeFeedback.feedbackType === 'IfNSW Other') {
				otherDescriptionValue = formData.cfeFeedback.feedbackTypeOther;
			};

			payload.attributes.type = formData.cfeFeedback.feedbackType,
			payload.attributes.details = formData.cfeFeedback.feedbackDetails,
			payload.attributes.otherDescription = otherDescriptionValue;
		}

		return (
			payload
		);
	};
};
