import React from 'react';
import T from 'prop-types';
import {inject, observer} from 'mobx-react';
import ResultsList from '@icare/components/lib/ResultsList/ResultsList';
import injectContent from '@icare/sitecore-content/lib/injectContent';

import Notification from '../Notification/';
import bellIconHover from './utils/bellIconHover';
import computeNotifications from '../../utils/computeNotifications';

@injectContent(['notificationBellIconOverlay'])
@inject('policyApplicationStore')
@observer
class NotificationBellOverlay extends React.Component {
	static propTypes = {
		policyApplicationStore: T.object.isRequired,
		content: T.object.isRequired,
	};

	componentDidMount() {
		const {getNotificationStatus} = this.props.policyApplicationStore;
		getNotificationStatus();
		bellIconHover(this.props.policyApplicationStore.asyncNotification.notifications);
	}

	render() {
		const {notificationBellIconOverlay} = this.props.content;
		const notifications = computeNotifications(this.props.policyApplicationStore.asyncNotification.notifications);
		const bellIconNotifications =
			notifications.length > 1 ? (notifications => notifications.slice(0, 2))(notifications) : notifications;

		return (
			<div id="bellIconNotificationOverlay" className="notificationOverlay">
				<ResultsList>
					{bellIconNotifications &&
						bellIconNotifications.length > 0 &&
						bellIconNotifications.map((notification, index) => {
							return (
								<Notification
									key={`${notification.id}__${index}`}
									notification={notification}
									content={notificationBellIconOverlay}
									resultItemKey={`${notification.id}__${index}`}
									bellIconNotificationOverlay
								/>
							);
						})}
				</ResultsList>
			</div>
		);
	}
}

export default NotificationBellOverlay;
