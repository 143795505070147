import request from 'superagent';
import AuthenticatedServices from './AuthenticatedServices';
import {IsMock, Endpoints, UrlConstants} from '../constants/Endpoints';

export default class DocumentUploaderService {
	static async uploadDocs(data, isQuote = true) {
		const payload = {data};
		const endpoint = `${isQuote ? Endpoints.postQuoteDocuments :  Endpoints.postPolicyDocuments}/${data.id}/${UrlConstants.attachments}`;
		const response = await request
			.post(endpoint)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.postDocuments));
		return response.body;
	}

	static async getAttachmentLocations(meta, id, isQuote = true) {
		const endpoint = `${isQuote ? Endpoints.quoteAttachmentLocation :  Endpoints.policyAttachmentLocation}/${id}/${UrlConstants.attachmentLocations}`;
		const payload = {meta};
		const response = await request
			.post(endpoint)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.attachmentLocation));
		return response.body;
	}
}
