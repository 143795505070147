import {
	BASE_API_URL,
	PORTAL_MOCK_URL,
	AGENCIES_ENDPOINT,
	CASE_TYPES_ENDPOINT,
	CUSTOMER_ME_COMPLAINT,
	CUSTOMER_ME_ENDPOINT,
	CUSTOMER_ME_ENQUIRY,
	CUSTOMER_ME_FEEDBACK,
	USERS_ENDPOINT,
	USER_TOKEN_VERIFICATION_ENDPOINT,
	//Below are the SI Moved Endpoints
	CLAIMS_SERVICE_PROVIDER,
	COC_AUTHORISED_PERSON_ENDPOINT,
	COC_CUSTOM_ENDPOINT,
	COC_STANDARD_ENDPOINT,
	EVENTS_CATEGORIES_ENDPOINT,
	EVENTS_ENDPOINT,
	PRODUCTS_ENDPOINT,
	// CRIF
	CRIF_API_URL,
	CRIF_MOCK_URL,
	PORTAL_BASE_URL,
} from '~/config';

export const IsMock = {
	agencies: false,
	caseTypes: false,
	complaint: false,
	customers: false,
	enquiry: false,
	feedback: false,
	users: false,
	userTokenVerification: false,
	// CRIF
	base: true,
	quotes: false,
	status: false,
	policies: false,
	getDocuments: false,
	postDocuments: false,
	attachmentLocation: false,
	agencyNames: false,
	coverageDetails: false,
	search: false,
	referenceData: false,
	headerSearch: false,
	// SI Moved Endpoints
	claimsServiceProvider: false,
	claimsTiles: false,
	cocAuthorisedPerson: false,
	cocCustom: false,
	cocStandard: false,
	eventsCategories: false,
	events: false,
};

export const UrlConstants = {
	quotes: 'quotes',
	policies: 'policies',
	agencyId: 'agencyID',
	attachments: 'attachments',
	attachmentLocations: 'attachmentLocations',
	generate: 'processes/generate',
	cancelPolicy: 'cancel',
	rejectQuote: 'reject',
	status: 'status',
};

export const Endpoints = {
	mockBase: PORTAL_MOCK_URL,
	apiBase: BASE_API_URL,
	agencies: `${IsMock.agencies ? PORTAL_MOCK_URL : BASE_API_URL}/${AGENCIES_ENDPOINT}`,
	caseTypes: `${IsMock.caseTypes ? PORTAL_MOCK_URL : BASE_API_URL}/${CASE_TYPES_ENDPOINT}`,
	complaint: `${IsMock.complaint ? PORTAL_MOCK_URL : BASE_API_URL}/${CUSTOMER_ME_COMPLAINT}`,
	customers: `${IsMock.customers ? PORTAL_MOCK_URL : BASE_API_URL}/${CUSTOMER_ME_ENDPOINT}`,
	enquiry: `${IsMock.enquiry ? PORTAL_MOCK_URL : BASE_API_URL}/${CUSTOMER_ME_ENQUIRY}`,
	feedback: `${IsMock.feedback ? PORTAL_MOCK_URL : BASE_API_URL}/${CUSTOMER_ME_FEEDBACK}`,
	users: `${IsMock.users ? PORTAL_MOCK_URL : BASE_API_URL}/${USERS_ENDPOINT}`,
	userTokenVerification: `${IsMock.userTokenVerification ? PORTAL_MOCK_URL : BASE_API_URL}/${USER_TOKEN_VERIFICATION_ENDPOINT}`,
	// CRIF
	base: `${IsMock.base ? CRIF_MOCK_URL : CRIF_API_URL}/me`,
	status: `${IsMock.status ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.status}`,
	quotes: `${IsMock.quotes ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.quotes}`,
	policies: `${IsMock.policies ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.policies}`,
	getQuoteDocuments: `${IsMock.getDocuments ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.quotes}`,
	getPolicyDocuments: `${IsMock.getDocuments ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.policies}`,
	postQuoteDocuments: `${IsMock.postDocuments ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.quotes}`,
	postPolicyDocuments: `${IsMock.postDocuments ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.policies}`,
	quoteAttachmentLocation: `${IsMock.attachmentLocation ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.quotes}`,
	policyAttachmentLocation: `${IsMock.attachmentLocation ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.policies}`,
	agencyNames: `${IsMock.agencyNames ? CRIF_MOCK_URL : CRIF_API_URL}/me/agencyNames`,
	referenceData: `${IsMock.referenceData ? CRIF_MOCK_URL : CRIF_API_URL}/referenceData`,
	coverageDetails: `${IsMock.coverageDetails ? CRIF_MOCK_URL : CRIF_API_URL}/me/${UrlConstants.quotes}/coverageDetails`,
	search: `${IsMock.search ? CRIF_MOCK_URL : CRIF_API_URL}/me/search`,
	advanceSearch: `${IsMock.headerSearch ? 'http://search-mock-service.getsandbox.com' : PORTAL_BASE_URL}/api/autocomplete/search?q=`,
	operationalReports: `${CRIF_API_URL}/me/reports/operational`,
	// SI Moved Endpoints
	claimsServiceProvider: `${IsMock.claimsServiceProvider ? PORTAL_MOCK_URL : BASE_API_URL}/${CLAIMS_SERVICE_PROVIDER}`,
	claimsTiles: `${IsMock.claimsTiles ? PORTAL_MOCK_URL : BASE_API_URL}/${PRODUCTS_ENDPOINT}`,
	cocAuthorisedPerson: `${IsMock.cocAuthorisedPerson ? PORTAL_MOCK_URL : BASE_API_URL}/${COC_AUTHORISED_PERSON_ENDPOINT}`,
	cocCustom: `${IsMock.cocCustom ? PORTAL_MOCK_URL : BASE_API_URL}/${COC_CUSTOM_ENDPOINT}`,
	cocStandard: `${IsMock.cocStandard ? PORTAL_MOCK_URL : BASE_API_URL}/${COC_STANDARD_ENDPOINT}`,
	eventsCategories: `${IsMock.eventsCategories ? PORTAL_MOCK_URL : BASE_API_URL}/${EVENTS_CATEGORIES_ENDPOINT}`,
	events: `${IsMock.events ? PORTAL_MOCK_URL : BASE_API_URL}/${EVENTS_ENDPOINT}`,
};
