import React from 'react';
import T from 'prop-types';
import GELFooter from '@icare/react-kit/lib/components/Footer';

export default class Footer extends React.PureComponent {
	static propTypes = {
		navLinks: T.array.isRequired,
	};

	render() {
		return (
			<GELFooter {...this.props} />
		);
	}
}
