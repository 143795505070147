import request from 'superagent';
import Cookies from 'universal-cookie';
import uuidv4 from 'uuid/v4';
import StickySessionService from '@icare/core/lib/services/StickySessionService';
import {USER_PROFILE_ID} from '~/config';
import {Endpoints, IsMock} from '../constants/Endpoints';

// TODO: This will become deprecated when GW Moves off F5 Load Balancer
// TODO: Should be done by June 2019
const _GWSessionCookieString = 'credential-cookie';
const _bigIpQuery = 'BIGipServerpl';

export default class AuthenticatedServices {
	static getHeaders(isMock = false , isCFECase = false) {
		if (isMock) {
			return {
				'X-OktaTokenJson': `header.${USER_PROFILE_ID}.signature`,
				'X-TrackingID': 'X-TrackingID',
				'X-InitialSystem': 'CRIF',
				'content-type': 'application/json',
				'Cache-Control': 'no-cache',
			};
		}

		const cookies = new Cookies();
		const localItem = JSON.parse(localStorage.getItem('okta-token-storage'));
		const OktaJWT = (localItem && localItem.idToken) ? localItem.idToken.idToken : cookies.get('OktaCookieJWT');
		const setInitialHeader = isCFECase ? 'IFNSW' : 'CRIF';

		return {
			Authorization: `Bearer ${OktaJWT}`,
			'X-TrackingID': `portalRequest-${uuidv4()}`,
			'X-InitialSystem': setInitialHeader,
			'Content-Type': 'application/vnd.api+json',
			'Cache-Control': 'no-cache',
			[StickySessionService.sessionHeaderKey]: StickySessionService.getSessionId(),
			[_GWSessionCookieString]: this.getDeprecatedStickySession(),
		};
	}

	// TODO: This will become deprecated when GW Moves off F5 Load Balancer
	// TODO: Should be done by June 2019
	static getDeprecatedStickySession() {
		return localStorage.getItem(_GWSessionCookieString) || null;
	}

	// TODO: This will become deprecated when GW Moves off F5 Load Balancer
	// TODO: Should be done by June 2019
	static setDeprecatedStickySession(headers) {
		const credentialHeader = headers[_GWSessionCookieString];
		if (credentialHeader) {
			const bigIpCookie = credentialHeader.split(',').filter(item => item.includes(_bigIpQuery)).toString().trim();
			const current = this.getDeprecatedStickySession();
			if (bigIpCookie && (!current || current !== bigIpCookie)) {
				localStorage.setItem(_GWSessionCookieString, bigIpCookie);
			}
		}
	}

	static async getAgencies() {
		const response = await request
			.get(Endpoints.agencyNames)
			.set(this.getHeaders(IsMock.agencyNames));
		return response.body.data;
	}

	static async getClientDetails() {
		return await request
			.get(Endpoints.customers)
			.set(this.getHeaders(IsMock.customers));
	}
}
