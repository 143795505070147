import {types, flow} from 'mobx-state-tree';
import moment from 'moment';

import {Status} from '@icare/components/lib/DownloadList/File';
import AuthenticatedServices from '~/core/services/AuthenticatedServices';
import PolicyServices from '~/core/services/PolicyServices';
import RouteUtils from '~/core/utils/RouteUtils';
import {sw, rms} from '~/core/constants/Agencies';
import {PolicyConstants, ReferralConstants, DistributionConstants} from '~/core/constants/ApplicationConstants';

export const defaultState = {
	urlParams: null,
	status: {
		isBusy: false,
		isGenerating: {
			quote: false,
			documents: false,
			invoices: false,
			supportingDocuments: false,
		},
		timedOut: false,
		generationError: null,
		error: null,
	},
	policyData: null,
	documents: null,
	documentExpiryTime: '',
	documentExpiryLength: 30,
	parentAgency: null,
	agencyList: [],
	hasEditAccess: false,
};

const AsyncStatus = types.model({
	isBusy: types.boolean,
	isGenerating: types.model({
		quote: types.boolean,
		documents: types.boolean,
		invoices: types.boolean,
		supportingDocuments: types.boolean,
	}),
	timedOut: types.boolean,
	error: types.frozen,
	generationError: types.frozen,
});

const Agency = types.model({
	id: types.string,
	name: types.string,
});

const UrlParams = types.model({
	typeId: types.string,
	agencyId: types.maybe(types.string),
	policyId: types.maybe(types.string),
});

export const PolicyDetailsStore = types
	.model('PolicyDetailsStore', {
		urlParams: types.maybe(UrlParams),
		status: AsyncStatus,
		policyData: types.frozen,
		documents: types.frozen,
		documentExpiryTime: types.string,
		documentExpiryLength: types.number,
		parentAgency: types.maybe(Agency),
		agencyList: types.array(Agency),
		hasEditAccess: types.boolean,
	})
	.views(self => {
		return {
			documentLinkExpired() {
				return moment().isAfter(this.documentExpiryTime);
			},
			pollPolicyDocuments() {
				const {isGenerating} = self.status;
				const {invoices, documents} = self.documents;
				const hasGenerating = isGenerating.documents || isGenerating.invoices || isGenerating.supportingDocuments;
				const hasDocs = !!documents.length && !!invoices.length;
				return hasGenerating || !hasDocs;
			},
			pollQuoteDocuments() {
				return self.status.isGenerating.documents || !self.documents.documents.length;
			},
			getAgencyName(id = null) {
				if (!self.policyData) {
					return false;
				}
				if (id) {
					return self.agencyList.find(agency => agency.id === id).name;
				}
				return self.agencyList.find(agency => agency.id === self.policyData.attributes.agencyId).name;
			},
			isQuote() {
				return self.urlParams.typeId === RouteUtils.QuoteLabel;
			},
			isRms() {
				if (!self.policyData) {
					return false;
				}
				return self.policyData.attributes.agencyId === rms.id;
			},
			isSw() {
				if (!self.policyData) {
					return false;
				}
				return self.policyData.attributes.agencyId === sw.id;
			},
			isRmsSw() {
				if (!self.policyData) {
					return false;
				}
				return self.isRms() || self.isSw();
			},
			isStatusException() {
				if (!self.policyData) {
					return false;
				}
				return (
					self.policyData.attributes.status === ReferralConstants.Status ||
					self.policyData.attributes.status === PolicyConstants.CancelledStatus
				);
			},
			canAmend() {
				if (!self.policyData) {
					return false;
				}
				const hasSums = parseInt(self.policyData.attributes.products.contractWorks.terms.sumsInsured) !== 0;
				const isActivePolicy = !self.isStatusException() && !self.isQuote();
				return (isActivePolicy || self.isQuote()) && self.hasEditAccess && hasSums;
			},
		};
	})
	.actions(self => {
		const updateExpiryLength = value => {
			self.documentExpiryLength = parseInt(value);
		};
		const updateQuoteGenerating = value => {
			self.status.isGenerating.quote = value;
		};
		const updateTimeout = () => {
			self.status.timedOut = true;
		};
		const updateEditAccess = () => {
			const {policyData, parentAgency} = self;
			if (policyData) {
				const matchedPolicy = policyData.attributes.agencyId === parentAgency.id;
				const notAnnual = policyData.attributes.distributionType !== DistributionConstants.Annual;
				self.hasEditAccess = matchedPolicy && notAnnual;
			} else {
				self.hasEditAccess = false;
			}
		};
		const updateUrlParams = params => {
			self.urlParams = params;
		};
		const updatePolicyData = policyData => {
			self.policyData = policyData;
		};

		const updateDocuments = documentData => {
			self.documents = documentData;
			self.documentExpiryTime = moment().add(self.documentExpiryLength, 'minutes').toString();
			self.status.isGenerating.documents = !!documentData.documents.find(doc => doc.status === Status.GENERATING);
			self.status.isGenerating.invoices = !!documentData.invoices.find(doc => doc.status === Status.GENERATING);
			self.status.isGenerating.supportingDocuments = !!documentData.supportingDocuments.find(
				doc => doc.status === Status.GENERATING,
			);
		};

		const updateAgencies = agencyResponse => {
			const {items} = agencyResponse.attributes;
			self.parentAgency = items.find(agency => agency.id === agencyResponse.id);
			self.agencyList = items;
		};

		const generateQuoteDocuments = flow(function* () {
			try {
				const {agencyId} = self.policyData.attributes;
				const {id} = self.policyData;
				self.status.isGenerating.documents = true;
				return yield PolicyServices.generateQuoteDocuments(id, agencyId);
			} catch (error) {
				self.status.isGenerating.documents = false;
				self.status.generationError = error;
			}
		});

		const getQuoteDocuments = flow(function* () {
			try {
				const {agencyId} = self.policyData.attributes;
				const {id} = self.policyData;
				const response = yield PolicyServices.retrieveQuoteDocuments(id, agencyId);
				updateDocuments(response.data.attributes);
			} catch (error) {
				self.status.generationError = error;
			}
		});

		const getPolicyDocuments = flow(function* () {
			try {
				const {agencyId} = self.policyData.attributes;
				const {id} = self.policyData;
				const response = yield PolicyServices.retrievePolicyDocuments(id, agencyId);
				updateDocuments(response.data.attributes);
			} catch (error) {
				self.status.documentError = error;
			}
		});

		const getApplication = flow(function* () {
			const {policyId, agencyId, typeId} = self.urlParams;
			const isQuote = typeId === RouteUtils.QuoteLabel;
			self.status.isBusy = true;
			self.status.error = null;
			try {
				const agencyResponse = yield AuthenticatedServices.getAgencies();
				const response = isQuote
					? yield PolicyServices.getQuote(policyId, agencyId)
					: yield PolicyServices.getPolicy(policyId, agencyId);
				updateAgencies(agencyResponse);
				updatePolicyData(response.data);
				updateEditAccess();
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				throw error;
			}
		});

		const reset = () => {
			self.status = defaultState.status;
			self.agencyId = defaultState.agencyId;
			self.policyId = defaultState.policyId;
			self.policyData = defaultState.policyData;
			self.documents = defaultState.documents;
		};

		return {
			getQuoteDocuments,
			getPolicyDocuments,
			generateQuoteDocuments,
			updateExpiryLength,
			updateQuoteGenerating,
			updateTimeout,
			updateEditAccess,
			updatePolicyData,
			updateDocuments,
			updateUrlParams,
			updateAgencies,
			getApplication,
			reset,
		};
	});

export default PolicyDetailsStore;
