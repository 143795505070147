import request from 'superagent';
import AuthenticatedServices from './AuthenticatedServices';
import { IsMock, Endpoints, UrlConstants } from '../constants/Endpoints';
import { QuoteConstants } from '../constants/ApplicationConstants';

export default class PolicyServices {
	static async addQuote(data) {
		const payload = { data };
		const response = await request
			.post(Endpoints.quotes)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.quotes));
		return response.body;
	}

	static async bindPolicy(data) {
		const payload = { data };
		const response = await request
			.post(Endpoints.policies)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.policies));
		return response.body;
	}

	static async getQuote(id, agency) {
		const response = await request
			.get(`${Endpoints.quotes}/${id}?${UrlConstants.agencyId}=${agency}`)
			.set(AuthenticatedServices.getHeaders(IsMock.quotes));
		return response.body;
	}

	static async rejectQuote(data, id) {
		const payload = { data };
		const response = await request
			.post(`${Endpoints.quotes}/${id}/${UrlConstants.rejectQuote}`)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.quotes));
		return response.body;
	}

	static async getPolicy(id, agency) {
		const response = await request
			.get(`${Endpoints.policies}/${id}?${UrlConstants.agencyId}=${agency}`)
			.set(AuthenticatedServices.getHeaders(IsMock.policies));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async cancelPolicy(data, id) {
		const payload = { data };
		const response = await request
			.post(`${Endpoints.policies}/${id}/${UrlConstants.cancelPolicy}`)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.policies));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async getOperationalReport(id) {
		const response = await request
			.get(`${Endpoints.operationalReports}?agencyID=${id}`)
			.set(AuthenticatedServices.getHeaders());
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body.data.attributes.url;
	}

	static async getProjectValues(agency, sumsInsured) {
		const payload = {
			data: {
				type: 'projectValues',
				attributes: {
					agencyId: agency,
					sumsInsured: sumsInsured,
				},
			},
		};

		const response = await request
			.post(Endpoints.coverageDetails)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.coverageDetails));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body.data.attributes.projectValues;
	}

	static async getReferenceData(agencyId) {
		const response = await request
			.get(`${Endpoints.referenceData}?${UrlConstants.agencyId}=${agencyId}`)
			.set(AuthenticatedServices.getHeaders(IsMock.referenceData));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body.data.attributes;
	}

	static async patchQuote(data) {
		const payload = { data };
		const response = await request
			.patch(`${Endpoints.quotes}/${data.id}?${UrlConstants.agencyId}=${data.attributes.agencyId}`)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.quotes));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async patchPolicy(data) {
		const payload = { data };
		const response = await request
			.patch(`${Endpoints.policies}/${data.id}?${UrlConstants.agencyId}=${data.attributes.agencyId}`)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.policies));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async retrievePolicyDocuments(id, agency) {
		const response = await request
			.get(
				`${Endpoints.getPolicyDocuments}/${id}/${UrlConstants.attachments}?${UrlConstants.agencyId}=${agency}`,
			)
			.set(AuthenticatedServices.getHeaders(IsMock.getDocuments));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async retrieveQuoteDocuments(id, agency) {
		const response = await request
			.get(
				`${Endpoints.getQuoteDocuments}/${id}/${UrlConstants.attachments}?${UrlConstants.agencyId}=${agency}`,
			)
			.set(AuthenticatedServices.getHeaders(IsMock.getDocuments));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static async generateQuoteDocuments(id, agency) {
		const payload = {
			data: {
				type: QuoteConstants.Type,
				attributes: {
					meta: {
						agencyId: agency,
					},
				},
			},
		};
		const response = await request
			.post(
				`${Endpoints.quotes}/${id}/${UrlConstants.generate}`,
			)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.quotes));
		AuthenticatedServices.setDeprecatedStickySession(response.headers);
		return response.body;
	}

	static getUserPolicies(
		page,
		pageSize = 10,
		criteria = {
			agencyId: '',
			projectNumber: '',
			policyNumber: '',
			projectName: '',
			status: [],
		},
		orderBy = [{ fieldName: 'createdDate', direction: 'ascending' }],
	) {
		return new Promise((resolve, reject) => {
			const payload = {
				data: {
					type: 'search',
					attributes: {
						params: {
							page: page || 1,
							pageSize: pageSize,
							orderBy: orderBy,
						},
						criteria: criteria,
					},
				},
			};
			request
				.post(Endpoints.search)
				.set(AuthenticatedServices.getHeaders(IsMock.search))
				.send(payload)
				.end((err, res) => {
					if (err) {
						reject(err);
					}
					const { meta, data } = res.body;
					const response = {
						totalResults: meta.totalRecords,
						totalPages: meta.totalPages,
						currentPage: meta.currentPage <= meta.totalPages ? meta.currentPage : 1,
						items: data.attributes.items,
					};
					resolve(response);
				});
		});
	}

	static async getNotification(ackId) {
		const response = await request
			.get(`${Endpoints.status}/${ackId}`)
			.set(AuthenticatedServices.getHeaders(IsMock.status));
		return response.body;
	}
}
