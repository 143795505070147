import RouteUtils from '~/core/utils/RouteUtils';
import {authenticationPaths} from '~/paths';

const routes = [
	{
		path: authenticationPaths.profile,
		requiresAuth: true,
		title: 'Your profile',
		component: RouteUtils.asyncComponent(() => import('./pages/ProfilePage')),
	},
	{
		path: authenticationPaths.login,
		title: 'Log in',
		hideLoginBtn: true,
		component: RouteUtils.asyncComponent(() => import('./pages/LoginPage')),
	},
	{
		path: authenticationPaths.logout,
		title: 'Logout',
		component: RouteUtils.asyncComponent(() => import('./pages/LogoutPage')),
	},
	{
		path: authenticationPaths.loggingInCallback,
		title: 'Logging in',
		component: RouteUtils.asyncComponent(() => import('./pages/LogInCallbackPage')),
	},
	{
		path: authenticationPaths.forgotPassword,
		title: 'Forgot password',
		hideLoginBtn: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ForgotPasswordPage')),
	},
	{
		path: authenticationPaths.resetPassword,
		title: 'Reset password',
		hideLoginBtn: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ResetPasswordPage')),
	},
	{
		path: authenticationPaths.expiredPasswordPage,
		title: 'Change password',
		hideLoginBtn: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ResetExpiredPasswordPage')),
	},
	{
		path: authenticationPaths.changePassword,
		title: 'Change password',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ChangePasswordPage')),
	},
	{
		path: authenticationPaths.setRecoveryQuestionPage,
		title: 'Set recovery question',
		component: RouteUtils.asyncComponent(() => import('./pages/SetRecoveryQuestionPage')),
	},
	{
		path: authenticationPaths.changeRecoveryQuestionPage,
		title: 'Set recovery question',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ChangeRecoveryQuestionPage')),
	},
	{
		path: authenticationPaths.loginCallback,
		title: 'Login callback',
		component: RouteUtils.asyncComponent(() => import('./pages/ImplicitCallbackPage')),
	},
];

export default routes;
