import RouteUtils from '~/core/utils/RouteUtils';

import {siPaths} from '~/paths';

const routes = [
	{
		path: siPaths.certificateOfCurrencyPage,
		title: 'Request a Certificate of Currency',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CertificateOfCurrencyPage');
		}),
	},
	{
		//Same page but with embedded id and agency
		path: siPaths.authorisedPersonPage,
		title: 'IfNSW',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/AuthorisedPersonPage');
		}),
	},
];

export default routes;
