const PolicyApplicationStoreDefault = {
	urlParams: null,
	agency: null,
	status: {
		isBusy: false,
		hasSubmitted: false,
		agencyError: false,
		projectValuesError: true,
		error: null,
		postError: false,
		patchError: false,
		openAsyncModal: false,
	},
	asyncNotification: {
		notifications: [],
	},
	referrals: {
		projectType: false,
		contaminatedLand: false,
		sumsInsured: false,
		isReferral: false,
	},
	parentAgency: null,
	isGettingValues: false,
	isBindingPolicy: false,
	policyData: null,
	referenceData: {
		projectType: null,
		completionPeriod: null,
		testingPeriod: null,
		defaultTestingPeriod: '',
		defaultPostCompletionPeriod: '',
	},
	referralTypes: [],
	projectValues: {
		lastSumsInsured: -1,
		contractWorks: {
			policyExcess: {
				defaultCode: '',
				values: [],
			},
			leg3Excess: {
				defaultCode: '',
				values: [],
			},
			existingProperty: {
				defaultCode: '',
				values: [],
			},
		},
		liabilities: {
			publicAndProductLiabilityLimit: {
				defaultCode: '',
				values: [],
			},
			environmentImpairmentLiabilityLimit: {
				defaultCode: '',
				values: [],
			},
			publicLiabilityExcess: {
				defaultCode: '',
				values: [],
			},
			workerExcess: {
				defaultCode: '',
				values: [],
			},
		},
		professionalIndemnity: {
			liabilityLimit: {
				defaultCode: '',
				values: [],
			},
			indemnityExcess: {
				defaultCode: '',
				values: [],
			},
		},
	},
};

export default PolicyApplicationStoreDefault;
