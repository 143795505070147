import request from 'superagent';
import {OKTA_CONFIG, NEW_USER_PROFILE_PAYLOAD} from '~/config';
import AuthenticatedServices from '~/core/services/AuthenticatedServices';
import {Endpoints, IsMock} from '~/core/constants/Endpoints';

export default class AccountService {
	static async getUserDetails() {
		const response = await request
			.get(Endpoints.customers)
			.set(AuthenticatedServices.getHeaders(IsMock.customers));
		return response.body.attributes;
	}

	static async setUserDetails(formData) {
		const payload = {
			data: {
				type: 'Contact',
				id: OKTA_CONFIG.clientId,
				attributes: {
					positionTitle: formData.yourDetails__positionTitle,
					preferredContactNumber: formData.contactDetails__preferredContactNumber,
				},
			},
		};

		// adapting the payload based on the config set in sitecore
		if (NEW_USER_PROFILE_PAYLOAD === 'true') {
			payload.data.attributes.homePhone = {
				extension: null,
				countryCode: null,
				number: formData.contactDetails__phone,
			};
			payload.data.attributes.mobilePhone = {
				extension: null,
				countryCode: null,
				number: formData.contactDetails__mobile,
			};
			payload.data.attributes.otherPhone = {
				extension: null,
				countryCode: null,
				number: formData.contactDetails__other,
			};
			payload.data.attributes.contactPreference = formData.contactDetails__contactMethod;
		} else {
			payload.data.attributes.phone = formData.contactDetails__phone;
			payload.data.attributes.mobilePhone = formData.contactDetails__mobile;
			payload.data.attributes.otherPhone = formData.contactDetails__other;
			payload.data.attributes.contactMethod = formData.contactDetails__contactMethod;
		}

		// adding versionNumber to the payload if defined
		if (formData.yourDetails__version) {
			payload.data.attributes.version = formData.yourDetails__version;
		}

		const response = await request
			.patch(Endpoints.customers)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.customers));
		return response.body;
	}
}
