import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.eventDetailsPage,
		title: 'Events Details',
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/EventDetailsPage'); // eslint-disable-line
		}),
	},
];

export default routes;
