import React from 'react';
import T from 'prop-types';
import withAuth from '@icare/auth-okta/lib/withAuth';
import GELHeader from '@icare/react-kit/lib/components/Header';
import {PORTAL_BASE_URL} from '~/config';
import {sitePaths} from '~/paths';


@withAuth
class Header extends React.Component {
	static propTypes = {
		auth: T.object.isRequired,
		header: T.object.isRequired,
	};

	render() {
		const {auth, header} = this.props;

		let homePath = auth.isAuthenticated ? sitePaths.dashboard : PORTAL_BASE_URL;
		if (process.env.NODE_ENV === 'development') {
			homePath = sitePaths.index;
		}

		return (
			<GELHeader header={header} title="" homePath={homePath} auth={auth} />
		);
	}
}

export default Header;
