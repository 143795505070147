import request from 'superagent';
import UnAuthenticatedServices from '~/core/services/UnAuthenticatedServices';
import {Endpoints, IsMock} from '~/core/constants/Endpoints';
import {ServiceLine} from '~/core/constants/ApplicationConstants';

export default class ParFormService {
	static async submitParForm(data, recaptchaToken) {
		const payload = {
			data: {
				type: 'UserPortalRequest',
				attributes: {
					serviceLine: ServiceLine,
					firstName: data.firstName,
					lastName: data.lastName,
					agency: {
						id: data.agency.value,
						name: data.agency.label,
					},
					positionTitle: data.positionTitle,
					email: data.workEmailAddress,
					contact: {
						phone: data.phone,
						mobile: data.mobile,
					},
					preferredContactNumber: data.preferredContactNumber,
				},
			},
		};

		const response = await request
			.post(Endpoints.users)
			.send(payload)
			.set(UnAuthenticatedServices.getHeaders(IsMock.users, recaptchaToken));
		return response;
	}

	static async getAgencies(randomToken) {
		const response = await request
			.get(`${Endpoints.agencies}?serviceLine=${ServiceLine}`)
			.set({
				'X-InitialSystem': ServiceLine,
				'X-Token': randomToken,
			});
		return response.body.data.attributes;
	}
};
