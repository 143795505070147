import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.parFormPage,
		title: 'Portal Access Request Form',
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ParFormPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.parConfirmationPage,
		title: 'Portal Access Request Confirmation',
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ParConfirmationPage'); // eslint-disable-line
		}),
	},
];

export default routes;
