import RouteUtils from '~/core/utils/RouteUtils';

import {crifPaths} from '~/paths';

const routes = [
	{
		path: crifPaths.newQuote,
		title: 'Get a Quote',
		isDynamicRouteRequired: true,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/PolicyApplicationPage');
		}),
	},
	{
		//Same page but with embedded id and agency
		path: crifPaths.amendApplication,
		isDynamicRouteRequired: true,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/PolicyApplicationPage');
		}),
	},
	{
		path: crifPaths.additionalInformation,
		isDynamicRouteRequired: true,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/AdditionalInformationPage');
		}),
	},
];

export default routes;
