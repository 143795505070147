import {types, flow} from 'mobx-state-tree';
import {EmailValidationFailedCode} from '~/core/constants/ApplicationConstants';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';
import ParFormService from '../services/ParFormService';

export const defaultState = {
	status: {
		isBusy: false,
		error: null,
		emailValidationError: null,
	},
	isFormSubmitted: false,
	formData: {
		parFormPage: {
			firstName: '',
			lastName: '',
			agency: {
				label: '',
				value: '',
			},
			positionTitle: '',
			workEmailAddress: '',
			phone: '',
			mobile: '',
			preferredContactNumber: '',
			agreeTerms: false,
		},
	},
	agenciesList: null,
};

const ParFormPage = types.model({
	firstName: types.maybe(types.string),
	lastName: types.maybe(types.string),
	agency: types.model({
		label: types.string,
		value: types.string,
	}),
	positionTitle: types.maybe(types.string),
	workEmailAddress: types.maybe(types.string),
	phone: types.maybe(types.string),
	mobile: types.maybe(types.string),
	preferredContactNumber: types.maybe(types.string),
	agreeTerms: types.maybe(types.boolean),
});

const FormData = types.model({
	parFormPage: ParFormPage,
});

export const ParFormStore = types
	.model('ParFormStore', {
		status: types.model({
			isBusy: types.boolean,
			error: types.frozen,
			emailValidationError: types.frozen,
		}),
		isFormSubmitted: types.boolean,
		formData: FormData,
		agenciesList: types.frozen,
	})
	.views(self => {
		return {
			didEmailValidationFail() {
				return (self.status.error.code === EmailValidationFailedCode);
			},
		};
	})
	.actions(self => {
		const updateFormData = formData => {
			self.formData.parFormPage = formData;
		};

		const populateStoreWithAgencies = flow(function* () {
			self.status.isBusy = true;
			self.status.error = null;

			// random Token requested by Mulesoft to fetch agencies
			const randomToken = Math.random().toString(36).substring(2, 15);

			try {
				const response = yield ParFormService.getAgencies(randomToken);
				self.agenciesList = response;
				self.status.isBusy = false;

			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error.response.body.errors[0].title;
				throw error;
			}
		});

		const submitForm = flow(function* (storeData, recaptchaToken) {
			self.status.isBusy = true;
			self.status.error = null;
			self.status.emailValidationError = null;

			try {
				yield ParFormService.submitParForm(storeData, recaptchaToken);
				updateFormData(storeData);
				AnalyticsHandler.trackParFormSubmission();

				self.status.isBusy = false;
				self.isFormSubmitted = true;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error.response.body.errors[0];

				if (self.didEmailValidationFail()) {
					self.status.emailValidationError = self.status.error.detail;
				}
			}
		});

		const resetToDefault = () => {
			self.status = defaultState.status;
			self.isFormSubmitted = defaultState.isFormSubmitted;
			self.formData = defaultState.formData;
		};

		const analyticsHandlerD4Errors = values => {
			AnalyticsHandler.trackParFormErrors(values);
		};

		return {
			submitForm,
			populateStoreWithAgencies,
			updateFormData,
			analyticsHandlerD4Errors,
			resetToDefault,
		};
	});

export default ParFormStore;
