import moment from 'moment';

const isoStrDateSecsPaddedZeros = isoDateStr => {
	const secondsRemovedStr = isoDateStr.substring(0, isoDateStr.lastIndexOf(':') + 1);

	const isoDateStrPadded = `${secondsRemovedStr}00.000Z`;
	return isoDateStrPadded;
};

const getNowAsIsoStr = () => {
	const date = new Date();
	const isoStringNow = date.toISOString();

	// replace seconds with zeros as start/end dates will be like this also
	const isoStringNowZeroSeconds = isoStrDateSecsPaddedZeros(isoStringNow);
	return isoStringNowZeroSeconds;
};
/**
 *
 * @param {String} isoDate iso date in USA readable format '10/18/2023 04:55'
 * @returns
 */
const dateStrToIsoStr = (isoDate = '') => {
	if (isoDate) {
		const dateObj = new Date(`${isoDate} UTC`);
		const isoStr = dateObj.toISOString();
		return isoStr;
	}
};

const isoStrToUnixExpoc = isoString => {
	const momentDate = moment(isoString);
	return momentDate.unix();
};

const isBannerEnabled = (bannerdata = {}) =>
	(bannerdata && bannerdata.enable && bannerdata.enable !== 'false') || bannerdata.enable === false;

const scheduledStartIsBeforeNow = (bannerdata = {}) => {
	const startDateIsoStr = dateStrToIsoStr(bannerdata.startDate);

	const nowAsIsoStr = getNowAsIsoStr();

	return startDateIsoStr <= nowAsIsoStr;
};

const scheduledEndIsAfterNow = (bannerdata = {}) => {
	const endDateIsoStr = dateStrToIsoStr(bannerdata.endDate);
	const nowAsIsoStr = getNowAsIsoStr();
	return endDateIsoStr >= nowAsIsoStr;
};

export {isBannerEnabled, scheduledStartIsBeforeNow, scheduledEndIsAfterNow, dateStrToIsoStr, isoStrToUnixExpoc};
