import RouteUtils from '~/core/utils/RouteUtils';

import {siPaths} from '~/paths';

const routes = [
	{
		path: siPaths.claimsHandover,
		title: 'Claims Handover',
		requiresAuth: true,
		requiresSearchQuery: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ClaimsHandoverPage');
		}),
	},
];

export default routes;
