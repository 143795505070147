export const policyTypes = [
	{
		value: 'projectName',
		label: 'Project name',
	},
	{
		value: 'policyNumber',
		label: 'Policy number',
	},
	{
		value: 'contractNumber',
		label: 'Contract number',
	},
];


export const filters = [
	{
		id: 'status',
		label: 'Status',
		type: 'checkbox',
		options: [
			{
				id: 'quote',
				label: 'Quote',
				value: 'Quote',
			},
			{
				id: 'referral',
				label: 'Referral',
				value: 'Referral',
			},
			{
				id: 'active',
				label: 'Active',
				value: 'Active',
			},
			{
				id: 'expired',
				label: 'Expired',
				value: 'Expired',
			},
			{
				id: 'cancelled',
				label: 'Cancelled',
				value: 'Cancelled',
			},
		],
	},
];

export const sortConfig = {
	id: 'sortBy',
	label: 'Sort by',
	options: [
		{
			id: 'newestToOldest',
			label: 'Newest to oldest',
			value: 'newestToOldest',
			fieldName: 'CreatedDate',
			direction: 'descending',
			isDefault: true,
		},
		{
			id: 'oldestToNewest',
			label: 'Oldest to newest',
			value: 'oldestToNewest',
			fieldName: 'CreatedDate',
			direction: 'ascending',
		},
	],
};
