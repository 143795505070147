import {AGENCY_IDS} from '~/config';

export const rms = {
	name: 'Roads and Maritime Services',
	id: AGENCY_IDS.rms,
};

export const sw = {
	name: 'Sydney Water',
	id: AGENCY_IDS.sw,
};
