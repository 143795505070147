import operationType from '../constants/OperationType';
import status from '../constants/NotificationStatus';

export default function computeNotifications(notifications) {
	let notificationsArray = notifications;
	notifications.forEach(Notification => {
		if (Notification.status === status.ERROR) {
			return;
		}
		if (Notification.operation === operationType.REJECT_QUOTE) {
			Notification.showURL = true;
			const relatedNotifications = notificationsArray.filter(
				notification =>
					notification.quoteNumber === Notification.quoteNumber &&
					notification.operation !== operationType.REJECT_QUOTE,
			);
			relatedNotifications.forEach(notification => (notification.showURL = false));
			notificationsArray = notifications.filter(notification => notification !== relatedNotifications);
			return;
		}
		if (Notification.operation === operationType.CREATE_POLICY) {
			Notification.showURL = true;
			const relatedNotifications = notificationsArray.filter(
				notification =>
					notification.quoteNumber === Notification.quoteNumber &&
					notification.operation !== operationType.CREATE_POLICY,
			);
			relatedNotifications.forEach(notification => (notification.showURL = false));
			notificationsArray = notifications.filter(notification => notification !== relatedNotifications);
			return;
		}
		Notification.showURL = true;
	});

	// sorts array
	const sortedNotifications = notificationsArray.sort((a, b) => {
		if (a.createdOn > b.createdOn) {
			return -1;
		} else if (b.createdOn > a.createdOn) {
			return 1;
		} else {
			return 0;
		}
	});
	return sortedNotifications;
}
