import {types, flow} from 'mobx-state-tree';

import AnalyticsHandler from '~/core/utils/AnalyticsHandler';
import RouteUtils from '~/core/utils/RouteUtils';
import DocumentUploaderService from '../services/DocumentUploaderService';

export const defaultState = {
	status: {
		isBusy: false,
		error: null,
	},
	supportingDocuments: [],
	isSubmitted: false,
	s3Documents: [],
};

const documentUploadStatus = types.model({
	isBusy: types.boolean,
	error: types.frozen,
});

const s3Documents = types.model({
	id: types.string,
	fileSize: types.string,
	fileType: types.string,
	removeLocation: types.frozen,
	uploadLocation: types.frozen,
});

const SupportingDocuments = types.model({
	link: types.string,
	objectKey: types.string,
	downloadUrl: types.string,
	mimeType: types.string,
	title: types.string,
	size: types.string,
	uploadDate: types.string,
});

export const SupportingDocumentsStore = types
	.model('SupportingDocumentsStore', {
		supportingDocuments: types.array(SupportingDocuments),
		status: documentUploadStatus,
		isSubmitted: types.boolean,
		s3Documents: types.array(s3Documents),
	})
	.actions(self => {
		const updateSupportingDocuments = documents => {
			self.supportingDocuments = documents
				? documents.map(d => {
					return {
						link: d.uploadLocation.url,
						objectKey: d.objectKey,
						downloadUrl: d.downloadLocation.url,
						mimeType: d.fileType,
						title: d.fileName,
						size: d.fileSize.toString(),
						uploadDate: new Date().toISOString(),
					};
				})
				: [];
			self.status.error = null;
		};

		// Uploads to DOC_02
		const uploadSupportingDocument = flow(function* (params) {

			self.status.isBusy = true;
			const {policyId, typeId, agencyId} = params;
			try {
				const request = {
					type: typeId,
					id: policyId,
					attributes: {
						agencyId: agencyId,
						documents: self.supportingDocuments,
					},
				};
				const res = yield DocumentUploaderService.uploadDocs(request, typeId === RouteUtils.QuoteLabel);
				if (res) {
					AnalyticsHandler.documentsUploaded();
					self.status.isBusy = false;
					self.status.error = null;
				}
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				throw error;
			}
		});

		return {
			updateSupportingDocuments,
			uploadSupportingDocument,
		};
	});

export default SupportingDocumentsStore;
