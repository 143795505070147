import {types, flow} from 'mobx-state-tree';
import AsyncStatus from '~/core/models/AsyncStatus';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';
import CfeFormService from '../services/CfeFormService';
import CfeFormRequestBuilder from '../utils/CfeFormRequestBuilder';

export const defaultState = {
	status: {
		isBusy: false,
		error: null,
	},
	isSubmitted: false,
	requestType: '',
	formData: {
		cfeGeneral: {
			enquiryDetails: '',
			enquiryType: '',
			enquiryTypeOther: '',
		},
		cfeFeedback: {
			feedbackDetails: '',
			feedbackType: '',
			feedbackTypeOther: '',
		},
		cfeComplaint: {
			complaintDetails: '',
			desiredOutcome: '',
			complaintType: '',
			complaintTypeOther: '',
		},
		cfeGlobal: {
			contactMeBack: '',
			firstName: null,
			lastName: null,
			contactMethod: null,
			contactPhone: null,
			phoneType: null,
			contactEmail: null,
		},
	},
	responseData: null,
	caseTypes: null,
};

const CfeGeneral = types.model({
	enquiryDetails: types.string,
	enquiryType: types.string,
	enquiryTypeOther: types.maybe(types.string),
});

const CfeComplaint = types.model({
	complaintDetails: types.string,
	desiredOutcome: types.maybe(types.string),
	complaintType: types.string,
	complaintTypeOther: types.maybe(types.string),
});

const CfeFeedback = types.model({
	feedbackDetails: types.string,
	feedbackType: types.string,
	feedbackTypeOther: types.maybe(types.string),
});

const CfeGlobal = types.model({
	contactMeBack: types.string,
	firstName: types.maybe(types.string),
	lastName: types.maybe(types.string),
	contactMethod: types.maybe(types.string),
	contactPhone: types.maybe(types.string),
	phoneType: types.maybe(types.string),
	contactEmail: types.maybe(types.string),
});

const FormData = types.model({
	cfeGeneral: CfeGeneral,
	cfeFeedback: CfeFeedback,
	cfeComplaint: CfeComplaint,
	cfeGlobal: CfeGlobal,
});

export const CfeFormStore = types
	.model('CfeFormStore', {
		requestType: types.string,
		status: AsyncStatus,
		isSubmitted: types.boolean,
		formData: FormData,
		responseData: types.frozen,
		caseTypes: types.frozen,
	})
	.actions(self => {
		const updateFormData = formData => {
			self.formData = formData;
		};

		const updateResponse = responseData => {
			self.responseData = responseData;
		};

		const updateActiveForm = requestType => {
			self.requestType = requestType;
		};

		const populateStoreWithCaseTypes = flow(function* () {
			self.status.isBusy = true;
			self.status.error = null;
			try {
				const response = yield CfeFormService.getCaseTypes();
				self.caseTypes = response;
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				console.error(error);
				throw error;
			}
		});

		const create = flow(function* (storeData) {
			self.status.isBusy = true;
			self.status.error = null;
			self.isSubmitted = true;

			try {
				const request = CfeFormRequestBuilder.createRequestFromStore(
					storeData,
				);
				const requestType = self.requestType;

				const response = yield CfeFormService.submitCfeForm(request, requestType);
				updateFormData(storeData.formData);
				updateResponse(response);
				AnalyticsHandler.trackCfeSubmit();
				self.status.isBusy = false;
			} catch (error) {
				self.status.isBusy = false;
				self.status.error = error;
				console.error(error);
				throw error;
			}
		});

		const resetToDefault = () => {
			self.isSubmitted = defaultState.isSubmitted;
			self.formData = defaultState.formData;
			self.requestType = defaultState.requestType;
		};

		return {
			create,
			populateStoreWithCaseTypes,
			updateFormData,
			updateResponse,
			updateActiveForm,
			resetToDefault,
		};
	});

export default CfeFormStore;
