const routes =
	[
		...require('~/pods/authentication/routes').default,
		...require('~/pods/certificate-of-currency/routes').default,
		...require('~/pods/cfe-form/routes').default,
		...require('~/pods/claims-handover/routes').default,
		...require('~/pods/crif-application/routes').default,
		...require('~/pods/crif-details/routes').default,
		...require('~/pods/event-details/routes').default,
		...require('~/pods/events-listing/routes').default,
		...require('~/pods/par-form/routes').default,
		...require('~/pods/user-token-verification/routes').default,
		...require('~/pods/site/routes').default,
	];

export default routes;
