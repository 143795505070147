export const QuoteConstants = {
	Status: 'Quote',
	Type: 'Quote',
};

export const ReferralConstants = {
	Status: 'Referral',
	Type: 'Quote',
	Classification: 'refer',
};

export const PolicyConstants = {
	Type: 'Policy',
	ActiveStatus: 'Active',
	CancelledStatus: 'Cancelled',
	ExpiredStatus: 'Expired',
};

export const DistributionConstants = {
	Annual: 'Annual',
	OneOff: 'one-off',
};

export const CancelWarning = 'Are you sure you want to leave? Changes you made will be lost.';

export const EmailValidationFailedCode = 'EmailDomainValidationFailed';

export const ServiceLine = 'IfNSW';
