import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.eventsListingPage,
		title: 'Events Listing',
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/EventsListingPage'); // eslint-disable-line
		}),
	},
];

export default routes;
