import React from 'react';
import T from 'prop-types';

import A11ySkipLinks from '@icare/components/lib/A11ySkipLinks';
import withAuth from '@icare/auth-okta/lib/withAuth';
import Page from '@icare/layouts/lib/Page';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import {SITECORE_PATHS, PORTAL_BASE_URL, MAIN_SITE_ROOT, ASYNC_MODAL_FEATURE} from '~/config';
import {sitePaths, authenticationPaths, crifPaths} from '~/paths';
import AdvanceSearchServices from '~/core/services/AdvanceSearchService';
import NotificationBellOverlay from '~/pods/site/components/NotificationBellOverlay/NotificationBellOverlay';

import {Endpoints} from '~/core/constants/Endpoints';
import Footer from '~/core/components/Footer';
import Header from '~/core/components/Header';
import Banner from '../core/components/Banner';

@injectContent(['ph', 'pf'])
@withAuth
class DefaultLayout extends React.Component {
	static propTypes = {
		children: T.node.isRequired,
		content: T.object.isRequired,
		auth: T.object.isRequired,
		hideLoginBtn: T.bool,
	};

	static defaultProps = {
		hideLoginBtn: false,
	};

	_asyncNotificaitionFeature = () => {
		const {content} = this.props;
		const {ph} = content;
		if (ASYNC_MODAL_FEATURE) {
			return [
				{
					link: crifPaths.notification,
					label: ph.notifications,
					showAuth: [false, true],
					icon: 'notification',
					mobileBar: true,
					mobileIcon: 'notification',
					target: '',
				},
			];
		}
		return [];
	};

	_getHeaderLinks = () => {
		const {auth, content, hideLoginBtn} = this.props;
		const {ph} = content;

		return {
			links: [
				{
					link: sitePaths.accessibilityPage,
					label: ph.accessibility,
					showAuth: [true, true],
					icon: '',
					mobileBar: false,
					mobileIcon: 'accessibility-settings',
					target: '',
				},
				{
					link: sitePaths.newsPage,
					label: ph.news,
					showAuth: [false, true],
					icon: '',
					mobileBar: false,
					mobileIcon: 'news',
					target: '',
				},
				{
					link: auth.isAuthenticated ? sitePaths.cfeFormPage : MAIN_SITE_ROOT + SITECORE_PATHS.contactUs,
					label: ph.contact,
					showAuth: [true, true],
					icon: '',
					mobileBar: false,
					mobileIcon: 'speech',
					target: '',
				},
				{
					link: sitePaths.dashboard,
					label: ph.home,
					showAuth: [false, true],
					icon: 'home',
					mobileBar: true,
					mobileIcon: 'home',
					target: '',
				},
				...this._asyncNotificaitionFeature(),
				{
					link: authenticationPaths.profile,
					label: ph.profile,
					showAuth: [false, true],
					icon: 'Person',
					mobileBar: true,
					mobileIcon: 'Person',
					target: '',
				},
			],
			search: {
				showAuth: [false, true], // show link in respectively unauth and auth state
				searchApi: Endpoints.advanceSearch, // Search API
				searchService: AdvanceSearchServices.getResults,
				searchPageUrl: `${PORTAL_BASE_URL}/${SITECORE_PATHS.pathToSearch.replace(/^\//, '')}`,
			},
			login: {
				showAuth: hideLoginBtn ? [false, false] : [true, true],
				loginPath: authenticationPaths.login,
				logoutPath: authenticationPaths.logout,
			},
		};
	};

	_getFooterLinks = () => {
		const {auth, content} = this.props;
		const {pf} = content;

		let contactUsLink = {
			title: pf.contact,
			link: `${MAIN_SITE_ROOT}/${SITECORE_PATHS.contactUs.replace(/^\//, '')}`,
			target: '_blank',
		};

		if (auth.isAuthenticated) {
			contactUsLink = {
				title: pf.contact,
				link: sitePaths.cfeFormPage,
			};
		}

		return [
			contactUsLink,
			{title: pf.privacy, link: SITECORE_PATHS.privacySettings},
			{title: pf.accessibility, link: SITECORE_PATHS.accessibilitySettings},
			{title: pf.terms, link: SITECORE_PATHS.termsConditions},
		];
	};

	render() {
		const {auth} = this.props;
		return (
			<Page
				fetchRef={page => {
					this.page = page;
				}}
			>
				<A11ySkipLinks skipToContentId="#main-content" />
				<div>{window.location.pathname !== '/login' && auth.isAuthenticated && <Banner />}</div>
				<div id="main-header">
					<Header header={this._getHeaderLinks()} />
					<NotificationBellOverlay />
				</div>

				<div id="main-content">{this.props.children}</div>
				<Footer navLinks={this._getFooterLinks()} />
			</Page>
		);
	}
}
export default DefaultLayout;
