import request from 'superagent';
import AuthenticatedServices from '~/core/services/AuthenticatedServices';
import {Endpoints, IsMock} from '~/core/constants/Endpoints';

export default class CfeFormService {
	static async submitCfeForm(data, formType) {
		const payload = {
			data,
		};

		let endpoint;

		switch (formType) {
			case 'Enquiry':
				endpoint = Endpoints.enquiry;
				break;
			case 'Complaint':
				endpoint = Endpoints.complaint;
				break;
			case 'Feedback':
				endpoint = Endpoints.feedback;
				break;
			default:
		}

		const response = await request
			.post(endpoint)
			.send(payload)
			.set(AuthenticatedServices.getHeaders(IsMock.customers, true));
		return response.body;
	}

	static async getCaseTypes() {
		const response = await request
			.get(Endpoints.caseTypes)
			.set(AuthenticatedServices.getHeaders(IsMock.caseTypes));
		return response.body.data.attributes;
	}
};
