import request from 'superagent';
import {Endpoints} from '../constants/Endpoints';

export default class AdvanceSearchServices {
	static async getResults(queryValue) {
		try {
			const response = await request
				.get(`${Endpoints.advanceSearch}${queryValue}`);
			return {
				autocomplete: response.body.autocomplete,
				total: response.body.total,
			};
		} catch (err) {
			console.error(err);
		}
	}
}
