import React from 'react';
import T from 'prop-types';

import injectContent from '@icare/sitecore-content/lib/injectContent';
import {isBannerEnabled, scheduledStartIsBeforeNow, scheduledEndIsAfterNow} from './bannerHelpers/bannerHelpers';

@injectContent(['ifnswbanner'])
class Banner extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
	};
	constructor(props) {
		super(props);
		const {bannerdata} = props.content.ifnswbanner;
		this.state = {displayBanner: this.initBannerState(bannerdata)};
	}

	initBannerState(bannerdata) {
		const isNotInLocalStore = !localStorage.getItem('Bannerstatus');

		const displayBanner =
			isBannerEnabled(bannerdata) &&
			scheduledStartIsBeforeNow(bannerdata) &&
			scheduledEndIsAfterNow(bannerdata) &&
			isNotInLocalStore;
		return displayBanner;
	}

	closeBanner = e => {
		e.stopPropagation();
		this.setState({displayBanner: false});
		localStorage.setItem('Bannerstatus', false);
	};

	render() {
		const {ifnswbanner} = this.props.content;

		const divStyle = {
			display: !this.state.displayBanner ? 'none' : null,
			backgroundColor:
				ifnswbanner.bannerdata && ifnswbanner.bannerdata.backgroundColor
					? ifnswbanner.bannerdata.backgroundColor
					: '#F5F5F5',
			color: ifnswbanner.bannerdata && ifnswbanner.bannerdata.textColor ? ifnswbanner.bannerdata.textColor : '#eeeee4',
			borderColor:
				ifnswbanner.bannerdata && ifnswbanner.bannerdata.borderColor ? ifnswbanner.bannerdata.borderColor : '#00A9E0',
		};

		return (
			<div className="Banner" style={divStyle}>
				<div className="Banner-content">
					{ifnswbanner.bannerdata ? (
						<div className="Banner-text">
							<img className="icon" src={ifnswbanner.bannerdata.icon.src} alt={ifnswbanner.bannerdata.icon.alt} />
							<div className="alert-message">
								<span dangerouslySetInnerHTML={{__html: ifnswbanner.bannerdata.Message}} />
								<a href={ifnswbanner.bannerdata.link} className="buttondata">
									{ifnswbanner.bannerdata.buttonText}
								</a>
							</div>
						</div>
					) : (
						<div className="Banner-text">
							<div className="alert-message">
								<p>No Banner Content.</p>
							</div>
						</div>
					)}
					<div className="button-wrapper">
						<button
							className="Banner-close"
							data-dismiss="alert"
							type="button"
							tabIndex={this.state.displayBanner ? '0' : '-1'}
							aria-label="Close"
							onClick={this.closeBanner}
						>
							×
						</button>
					</div>
				</div>
			</div>
		);
	}
}
export default Banner;
