import {ServiceLine} from '~/core/constants/ApplicationConstants';

export default class UnAuthenticatedServices {
	static getHeaders(isMock, recaptchaToken) {
		if (isMock) {
			return {
				'X-TrackingID': '',
				'content-type': 'application/json',
				'Cache-Control': 'no-cache',
				'X-InitialSystem': ServiceLine,
				'X-Token': recaptchaToken,
			};
		}

		return {
			Authorization: 'Bearer',
			'X-TrackingID': '',
			'Content-Type': 'application/vnd.api+json',
			'Cache-Control': 'no-cache',
			'X-InitialSystem': ServiceLine,
			'X-Token': recaptchaToken,
		};
	}
}
