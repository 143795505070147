import React from 'react';
import { useStrict } from 'mobx';
import { Provider } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import favicon from '@icare/core/lib/statics/images/favicon.ico';
import faviconMs from '@icare/core/lib/statics/images/metro-tile.png';
import faviconApple from '@icare/core/lib/statics/images/apple-touch-icon.png';
import '@icare/core/lib/utils/typekit';
import AccessibilitySettings from '@icare/react-kit/lib/components/AccessibilitySettings';
import AuthProvider from '@icare/auth-okta/lib/AuthProvider';
import SecureRoute from '@icare/auth-okta/lib/SecureRoute';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';
import { CRIF_USER_ROLE, OKTA_CONFIG } from '~/config';
import DefaultLayout from './layouts';
import stores from './stores';
import routes from './routes';
import { authenticationPaths, sitePaths } from './paths';
import './styles/index.scss';

useStrict(true);

class App extends React.Component {
	constructor(props) {
		super(props);
		AccessibilitySettings.setAccessibilityFont();
		AccessibilitySettings.setAccessibilityContrast();
		this.clearNotificationSession();
	}

	clearNotificationSession() {
		const oktaObj = JSON.parse(localStorage.getItem('okta-token-storage'));
		if (oktaObj && !oktaObj.idToken) {
			window.sessionStorage.removeItem('notifications');
		}
	}

	passUserIdToGA = user => {
		// CRM Contact ID is used as GA UserID if available
		if (user && user.oktaId) {
			AnalyticsHandler.logUid(user.oktaId);
			return;
		}

		// CRM Contact ID is empty, user is not logged in
		// Initiate load of GA tag
		AnalyticsHandler.initiateLoadOfGATag();
	};

	render() {
		return (
			<Provider {...stores}>
				<AuthProvider config={OKTA_CONFIG} onDidInitilise={this.passUserIdToGA}>
					<BrowserRouter basename="/">
						<Switch>
							{routes.map(route => {
								const { component: Component, layout, exact, path } = route;
								const Layout = layout || DefaultLayout;

								const showPath = process.env.NODE_ENV === 'production' ? !route.devOnly : true;
								const MaybeSecureRoute = route.requiresAuth ? SecureRoute : Route;
								const securityProps = {
									loginPath: authenticationPaths.login,
									forbiddenUrl: sitePaths.unauthorisedPage,
									requiredRoles: route.crifRoute ? [CRIF_USER_ROLE] : [],
									requiresSearchQuery: route.requiresSearchQuery,
								};

								return showPath ? (
									<MaybeSecureRoute
										key={path}
										exact={typeof exact === 'boolean' ? exact : true}
										path={path}
										{...securityProps}
										render={props => {
											return (
												<Layout {...props} {...route}>
													<Helmet>
														<title>{`${route.title || ''} | icare`}</title>
														<meta name="theme-color" content="#FFF" />
														<meta name="msapplication-TileColor" content="#000" />
														<meta name="msapplication-TileImage" content={faviconMs} />
														<link rel="apple-touch-icon-precomposed" href={faviconApple} />
														<link rel="shortcut icon" href={favicon} />
													</Helmet>
													<Component {...props} {...route.props} routes={route.routes} />
												</Layout>
											);
										}}
									/>
								) : null;
							})}
						</Switch>
					</BrowserRouter>
				</AuthProvider>
			</Provider>
		);
	}
}

export default App;
