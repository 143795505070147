import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.cfeFormPage,
		title: 'Contact Us',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeFormPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.cfeGeneralEnquiriesFormPage,
		title: 'General Enquiries',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeGeneralEnquiriesFormPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.cfeComplaintsFormPage,
		title: 'Complaints',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeComplaintsFormPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.cfeFeedbackFormPage,
		title: 'Feedback',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeFeedbackFormPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.cfeFormThankYouPage,
		title: 'CFE Form Thank You',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeFormThankYouPage'); // eslint-disable-line
		}),
	},
	{
		path: sitePaths.cfeFormErrorPage,
		title: 'CFE Error Page',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CfeFormErrorPage'); // eslint-disable-line
		}),
	},
];

export default routes;
