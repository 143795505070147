import {types as T, flow} from 'mobx-state-tree';
import Address, {AddressFactory} from '~/core/models/Address';
import AsyncStatus from '~/core/models/AsyncStatus';
import AccountService from '../services/AccountService';

export const defaultState = {
	_user: null,
	_roles: null,
	status: {
		isBusy: false,
		error: null,
	},
};

const User = T.model({
	firstName: T.string,
	lastName: T.maybe(T.string),
	address: T.maybe(Address),
	email: T.string,
	phone: T.maybe(T.string),
	availablePhones: T.maybe(T.string),
	contactMethod: T.maybe(T.string),
	phonePreference: T.maybe(T.string),
	phoneType: T.maybe(T.string),
	preferredContactNumber: T.maybe(T.string),
});

export const AuthStore = T.model('authentication', {
	_user: T.maybe(User),
	_roles: T.maybe(T.array(T.string)),
	status: AsyncStatus,
}).views(self => {
	return {
		user() {
			return self._user || {};
		},
		roles() {
			return self._roles || {};
		},
	};
}).actions(self => {
	// Fetch user properties from endpoint and call action setUser to update
	const syncUserDetails = flow(function* () {
		self.status.isBusy = true;

		try {
			const resp = yield AccountService.getUserDetails();
			self.user.firstName = resp.firstname || '-';
			self.user.lastName = resp.lastname || '-';
			self.user.address = AddressFactory.createAddressFromObject(resp.address);
			self.user.email = resp.contacts.emailAddress || '-';
			self.user.phone = (() => {
				const phone = resp.contacts.phone.find(x => x.type === 'phone');
				if (phone) {
					return phone.number;
				}
				return '';
			})();

			self.user.availablePhones = resp.contacts && resp.contacts.phone ? resp.contacts.phone : '';

			// 'Phone' or 'Email' is sent through, we lowercase it to 'phone' or 'email'
			let contactMethod = '';
			if (resp.contacts) {
				if (resp.contacts.contactPreference) {
					contactMethod = resp.contacts.contactPreference.toLowerCase();
				} else if (resp.contacts.communicationPreference) {
					contactMethod = resp.contacts.communicationPreference.toLowerCase();
				}
			}
			self.user.contactMethod = contactMethod;

			// 'Mobile', 'Phone', 'Other' or 'Fax' is sent through, we lowercase it to 'mobile', 'phone', 'other' or 'fax'
			const phonePreference = resp.contacts && resp.contacts.phonePreference ? resp.contacts.phonePreference.toLowerCase() : '';
			self.user.phonePreference = phonePreference;
			self.user.phoneType = phonePreference;

			// the attributes > contacts > phone array includes all user phone types and numbers e.g.
			// "phone":[{"type":"mobile","number":"0909787444","countryCode":"AU","extension":null},{"type":"phone","number":"098989898","countryCode":"AU","extension":null},{"type":"other","number":null,"countryCode":null,"extension":null},{"type":"fax","number":null,"countryCode":null,"extension":null}]
			// the preferredContactNumber stores the (phone) number according to the (phonePreference) type
			// e.g. if phonePreference === 'mobile' then preferredContactNumber = "0909787444"
			let preferredContactNumber = '';
			if (resp.contacts && resp.contacts.phone && phonePreference) {
				const findPhonePreferenceNumber = resp.contacts.phone.find(phone => phone.type === phonePreference);
				if (findPhonePreferenceNumber) {
					preferredContactNumber = findPhonePreferenceNumber.number;
				}
			}
			self.user.preferredContactNumber = preferredContactNumber;

			self.status.isBusy = false;

			return self.user;

		} catch (err) {
			self.status.isBusy = false;
			self.status.error = err;
			throw err;
		}
	});

	const setUserDetails = (key, value) => {
		return self.user[key] = value;
	};

	return {
		syncUserDetails,
		setUserDetails,
	};
});

export default AuthStore;
