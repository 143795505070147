import { types } from 'mobx-state-tree';

const AsyncStatus = types.model({
	isBusy: types.boolean,
	error: types.frozen,
	hasSubmitted: types.boolean,
	agencyError: types.boolean,
	projectValuesError: types.boolean,
	postError: types.boolean,
	patchError: types.boolean,
	openAsyncModal: types.boolean,
});

const Agency = types.model({
	id: types.string,
	name: types.string,
});

const UrlParams = types.model({
	typeId: types.string,
	agencyId: types.maybe(types.string),
	policyId: types.maybe(types.string),
});

const Referrals = types.model({
	projectType: types.boolean,
	contaminatedLand: types.boolean,
	sumsInsured: types.boolean,
	isReferral: types.boolean,
});

const Option = types.model({
	value: types.string,
	label: types.string,
	id: types.string,
});

const ReferenceData = types.model({
	projectType: types.maybe(types.array(Option)),
	completionPeriod: types.maybe(types.array(Option)),
	testingPeriod: types.maybe(types.array(Option)),
	defaultTestingPeriod: types.string,
	defaultPostCompletionPeriod: types.string,
});


const ProjectValuesArray = types.model({
	defaultCode: types.string,
	values: types.array(types.string),
});

const ContractWorks = types.model({
	policyExcess: ProjectValuesArray,
	leg3Excess: ProjectValuesArray,
	existingProperty: ProjectValuesArray,
});

const Liabilities = types.model({
	publicAndProductLiabilityLimit: ProjectValuesArray,
	environmentImpairmentLiabilityLimit: ProjectValuesArray,
	publicLiabilityExcess: ProjectValuesArray,
	workerExcess: ProjectValuesArray,
});

const ProfessionalIndemnity = types.model({
	liabilityLimit: ProjectValuesArray,
	indemnityExcess: ProjectValuesArray,
});

const ProjectValues = types.model({
	lastSumsInsured: types.number,
	contractWorks: ContractWorks,
	liabilities: Liabilities,
	professionalIndemnity: ProfessionalIndemnity,
});

const NotificationDetails = types.model({
	type: types.string,
	id: types.string,
	ackId: types.string,
	status: types.string,
	operation: types.string,
	quoteNumber: types.string,
	policyNumber: types.string,
	projectName: types.string,
	agencyId: types.string,
	consumer: types.string,
	createdOn: types.string,
	errorCode: types.string,
	errorMessage: types.string,
});

const AsyncNotificationValues = types.model({
	notifications: types.array(NotificationDetails),
});

const PolicyApplicationStoreModel = {
	referenceData: ReferenceData,
	referralTypes: types.array(types.string),
	urlParams: types.maybe(UrlParams),
	agency: types.maybe(Agency),
	status: AsyncStatus,
	isGettingValues: types.boolean,
	referrals: Referrals,
	policyData: types.frozen,
	projectValues: ProjectValues,
	asyncNotification: AsyncNotificationValues,
};

export default PolicyApplicationStoreModel;
