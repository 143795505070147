import {types} from 'mobx-state-tree';
import {policyTypes} from '../constants/FilterConstants';

export const defaultState = {
	agencyList: [],
	selectedAgency: {
		id: '',
		name: '',
	},
	selectedType: {
		key: '',
		label: '',
		value: '',
	},
	isLoading: false,
};

const Type = types.model({
	key: types.string,
	label: types.string,
	value: types.maybe(types.string),
});

const Agency = types.model({
	id: types.string,
	name: types.string,
});

export const ListingStore = types
	.model('ListingStore', {
		agencyList: types.array(Agency),
		selectedAgency: Agency,
		selectedType: Type,
		isLoading: types.boolean,
	})
	.actions(self => {
		return {
			toggleLoading(toggle) {
				self.isLoading = toggle;
			},
			setAgencyList(list) {
				self.agencyList = list;
			},
			setSelectedAgency(agency) {
				self.selectedAgency = {
					id: agency.id,
					name: agency.name,
				};
			},
			setSelectedType(key) {
				const type = policyTypes.find(type => type.value === key);
				self.selectedType = {
					key: type.value,
					label: type.label,
					value: '',
				};
			},
			clearSelectedTypeValue() {
				self.selectedType.value = '';
			},
			setSelectedTypeValue(value) {
				self.selectedType.value = value;
			},
		};
	});
