import RouteUtils from '~/core/utils/RouteUtils';
import {crifPaths} from '~/paths';

const routes = [
	{
		path: crifPaths.policyListing,
		isDynamicRouteRequired: false,
		title: 'Policy Search',
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ListingPage');
		}),
	},
	{
		path: crifPaths.details,
		isDynamicRouteRequired: true,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/DetailsPage');
		}),
	},
	{
		path: crifPaths.cancel,
		title: 'Cancel',
		isDynamicRouteRequired: true,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/CancelPage');
		}),
	},
	{
		path: crifPaths.thankYou,
		title: 'Thank you',
		isDynamicRouteRequired: false,
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ThankYouPage'); // eslint-disable-line
		}),
	},
];

export default routes;
