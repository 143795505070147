import React from 'react';
import FatalErrorPage from '~/pods/site/pages/FatalErrorPage/';
import {policyId, agencyId, typeId} from '~/paths';

const quoteLabel = 'quote';
const policyLabel = 'policy';
const quoteStatus = 'Quote';

export default class RouteUtils {
	static get QuoteLabel() {
		return quoteLabel;
	}

	static get PolicyLabel() {
		return policyLabel;
	}

	static get QuoteStatus() {
		return quoteStatus;
	}

	static setQuoteLink(path, id, agency) {
		if (id) {
			path = path.replace(policyId, id);
		}
		if (agency) {
			path = path.replace(agencyId, agency);
		}
		return path.replace(typeId, quoteLabel);
	}
	static setPolicyLink(path, id, agency) {
		if (id) {
			path = path.replace(policyId, id);
		}
		if (agency) {
			path = path.replace(agencyId, agency);
		}
		return path.replace(typeId, policyLabel);
	}

	static asyncComponent(getComponent) {
		return class AsyncComponent extends React.Component {
			static Component = null;
			state = {Component: AsyncComponent.Component, hasError: false};

			componentDidCatch(error, info) {
				this.setState({hasError: true, error, info});
			}

			componentDidMount() {
				if (!this.state.Component) {
					getComponent().then(({default: Component}) => {
						AsyncComponent.Component = Component;
						this.setState({Component});
					});
				}
			}

			render() {
				if (this.state.hasError) {
					return <FatalErrorPage error={this.state.error} info={this.state.info} />;
				}
				const {Component} = this.state;
				if (Component) {
					return <Component {...this.props} />;
				}
				return null;
			}
		};
	}
}
