import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths, crifPaths} from '~/paths';

const routes = [
	{
		path: sitePaths.index,
		title: 'Index',
		component: RouteUtils.asyncComponent(() => import('./pages/HomePage')),
	},
	{
		path: sitePaths.dashboard,
		title: 'Dashboard',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => import('./pages/Dashboard')),
	},
	{
		path: sitePaths.notFoundPage,
		title: 'Page not Found',
		component: RouteUtils.asyncComponent(() => import('./pages/NotFoundPage')),
	},
	{
		path: sitePaths.fatalErrorPage,
		title: 'Fatal Error',
		component: RouteUtils.asyncComponent(() => import('./pages/FatalErrorPage')),
	},
	{
		path: sitePaths.expiredPage,
		title: 'Expired Link',
		component: RouteUtils.asyncComponent(() => import('./pages/ExpiredPage')),
	},
	{
		path: sitePaths.unauthorisedPage,
		title: 'Access Denied',
		component: RouteUtils.asyncComponent(() => import('./pages/UnauthorisedPage')),
	},
	{
		path: sitePaths.accessibilityPage,
		title: 'Accessibility',
		component: RouteUtils.asyncComponent(() => import('./pages/AccessibilityPage')),
	},
	{
		path: crifPaths.notification,
		title: 'Notifications',
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => import('./pages/NotificationPage')),
	},
	{
		path: crifPaths.quotePolicyCancelConfirmationPage,
		title: 'Thank You',
		requiresAuth: true,
		crifRoute: true,
		component: RouteUtils.asyncComponent(() => import('./pages/ThankyouPage')),
	},
	{
		path: sitePaths.newsPage,
		title: 'News',
		component: RouteUtils.asyncComponent(() => import('./pages/NewsPage')),
	},
	{
		path: sitePaths.catastropheClaimsPage,
		title: 'Catastrophe Claims',
		component: RouteUtils.asyncComponent(() => import('./pages/CatastropheClaimsPage')),
	},
];

export default routes;
