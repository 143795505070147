import { AuthStore, defaultState as AuthStoreDS } from '~/pods/authentication/stores/AuthStore';
/* @@GENERATOR:STORE:IMPORT */
import { ContentStore } from '~/core/stores/ContentStore';
// CRIF
import {
	SupportingDocumentsStore,
	defaultState as SupportingDocumentsStoreDS,
} from '~/core/stores/SupportingDocumentsStore';
import { PolicyDetailsStore, defaultState as PolicyDetailsStoreDS } from '~/pods/crif-details/stores/PolicyDetailsStore';
import { ListingStore, defaultState as ListingStoreDS } from '~/pods/crif-details/stores/ListingStore';
import {
	PolicyApplicationStore,
	defaultState as PolicyApplicationStoreDS,
} from '~/pods/crif-application/stores/PolicyApplicationStore';
// D4
import { CfeFormStore, defaultState as CFEFormStoreDS } from '~/pods/cfe-form/stores/CfeFormStore';
import { ParFormStore, defaultState as PARFormStoreDS } from '~/pods/par-form/stores/ParFormStore';

const stores = {
	/* @@GENERATOR:STORE:ASSIGN */
	contentStore: ContentStore.create(),
	authStore: AuthStore.create(AuthStoreDS),
	// CRIF
	policyDetailsStore: PolicyDetailsStore.create(PolicyDetailsStoreDS),
	listingStore: ListingStore.create(ListingStoreDS),
	policyApplicationStore: PolicyApplicationStore.create(PolicyApplicationStoreDS),
	supportingDocumentsStore: SupportingDocumentsStore.create(SupportingDocumentsStoreDS),
	// D4
	cfeFormStore: CfeFormStore.create(CFEFormStoreDS),
	parFormStore: ParFormStore.create(PARFormStoreDS),
};

export default stores;
