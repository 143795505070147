import {types as T} from 'mobx-state-tree';

export const Address = T.model('Address', {
	id: T.maybe(T.string),
	type: T.maybe(T.string),
	addressLine1: T.maybe(T.string), //TODO allows null since in mulesoft it respond with null value
	addressLine2: T.maybe(T.string),
	addressLine3: T.maybe(T.string),
	locality: T.maybe(T.string),
	postalCode: T.maybe(T.string),
	country: T.maybe(T.string),
	province: T.maybe(T.string),
	unstructured: T.maybe(T.string),
}).views(self => {
	return {
		toString: () => {
			const properties = [
				self.addressLine1 || '',
				self.addressLine2 || '',
				self.addressLine3 || '',
				self.locality || '',
				self.postalCode || '',
				self.country || '',
				self.province || '',
			];
			return properties.join(' ');
		},
		isEmpty: () => {
			return (
				!self.id &&
				!self.addressLine1 &&
				!self.addressLine2 &&
				!self.addressLine3 &&
				!self.locality &&
				!self.postalCode &&
				!self.country &&
				!self.province &&
				!self.unstructured
			);
		},

		toServiceLayerObject: () => {
			return {
				type: 'postal',
				addressLine1: self.addressLine1,
				addressLine2: self.addressLine2,
				addressLine3: self.addressLine3,
				city: self.locality,
				state: self.province,
				postCode: self.postalCode,
				country: self.country,
				unstructuredAddress: self.unstructured,
			};
		},
	};
});

export class AddressFactory {
	static createAddressFromObject(o) {
		if (!o) {
			return null;
		}
		if (Array.isArray(o)) {
			try {
				const addressFromArray = Address.create({
					id: o['0'].id || null,
					type: o['0'].type || null,
					addressLine1: o['0'].addressLine1 || null,
					addressLine2: o['0'].addressLine2 || null,
					addressLine3: o['0'].addressLine3 || null,
					locality: o['0'].locality || o['0'].city || null,
					province: o['0'].province || o['0'].state || null,
					postalCode: o['0'].postalCode || o['0'].postCode || null,
					country: o['0'].country || null,
					unstructured: o['0'].unstructured || null,
				});
				if (addressFromArray.isEmpty()) {
					return null;
				}
				return addressFromArray;
			} catch (err) {
				console.error(err);
			}
		}
		const address = Address.create({
			id: o.id || null,
			type: o.type || null,
			addressLine1: o.addressLine1 || null,
			addressLine2: o.addressLine2 || null,
			addressLine3: o.addressLine3 || null,
			locality: o.locality || null,
			province: o.province || null,
			postalCode: o.postalCode || o.postCode || null,
			country: o.country || null,
			unstructured: o.unstructured || null,
		});
		if (address.isEmpty()) {
			return null;
		}
		return address;
	}
}

export default Address;
